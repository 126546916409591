<template>
<!-- main -->
<main id="container" class="newScrollAction" data-name="recruitFa">
    <h1 class="screen_out">개인영업(FA) 채용</h1>

    <!-- 메인비주얼 -->
    <section class="visual">
        <p class="txt">
            <span>FA가 가장 행복한 회사</span>
            <strong>피플라이프</strong>
            <em>금융판매 전문회사</em>
        </p>
        <div class="visual_story">
            <div class="success_story">
                <h3 class="title">피플라이프 성공사례 인터뷰</h3>
                <p class="sub_title"> 피플라이프의 전문적인 경력개발, 교육체계 통해 <br> 성장한 피플人 들의 성공 스토리 인터뷰 </p>
                <div class="top">
                    <div class="tab_buttons">
                        <button type="button" class="tab_btn"><span data-text="idName"></span></button>
                    </div>
                    <div class="first_story">
                        <div>
                            <a href="javascript:void(0)" data-type="youtube" title="영상재생">
                                <div class="thumb_wrap">
                                    <img src="/img/sales/fa/pic_fa_process_img05.jpg" alt="" class="thumb">
                                    <i class="ico_play"></i>
                                </div>
                                <div class="desc_cnt">
                                    <p class="tit"><span data-text="title"></span></p>
                                    <div class="desc"><span data-text="descriptionMemo"></span></div>
                                </div>
                           </a>
                        </div>
                    </div>
                </div>
                <div class="list inner">
                    <ul class="stroy_list">
                        <li>
                            <a href="javascript:void(0)" data-attr-title="title" data-type="youtube" title="영상재생">
                                <div class="thumb_wrap">
                                    <img src="" alt="" class="thumb">
                                    <i class="ico_play"></i>
                                </div>
                                <div class="desc_cnt">
                                    <p class="tit"><span data-text="title" data-clamp="2"></span></p>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <button type="button" class="stroymore_btn">
                        <i></i>
                        더보기
                    </button>
                </div>
            </div>
        </div>
       
    </section>
    <!-- //메인비주얼 -->

    <!-- 7대 영업지원 -->
    <section id="sevenfa" class="fasupport">
        <h3 class="title">FA 7대 영업지원 시스템</h3>
        <p class="sub_title"> FA가 고객에게 가장 자신감 있게 서비스 할 수 있도록<br>든든한 지원 인프라를 구축하고 있습니다. </p>
        <div class="menu_list">
            <ul class="top_menu off">
                <li><button type="button" data-target="#falist1">교육지원</button></li>
                <li><button type="button" data-target="#falist2">광고 &middot; 홍보 지원</button></li>
                <li><button type="button" data-target="#falist3">상품제휴</button></li>
                <li><button type="button" data-target="#falist4">전문가 네트워크</button></li>
                <li><button type="button" data-target="#falist5">고객세미나 지원</button></li>
                <li><button type="button" data-target="#falist6">전산 시스템</button></li>
                <li><button type="button" data-target="#falist7">DB제공 시스템</button></li>
            </ul>
        </div>
    </section>
    <!-- 7대 영업지원 -->

    <!-- 교육지원 -->
    <section id="falist1" class="education stepnum">
        <h3 class="title"><span class="num">01</span>교육지원</h3>
        <div class="info">
            <p class="img"><img src="/img/recruit/recruitFa/img_education01.jpg" alt=""></p>
            <div class="layer_contents">
                <dl>
                    <dt>법인영업교육</dt>
                    <dd>
                        법인 대상 컨설팅 영업을 조직화하여 사업적 비전과 품격을 한 단계 업그레이드한 원동력은 바로 체계적인 교육의 힘입니다.
                    </dd>
                </dl>
                <button type="button" aria-expanded="false" class="arrow_btn plus">교육과정 상세<img class="ico" src="" data-images-path="/image/svg/plus01.svg"></button>
                <div class="detail" aria-expanded="false">
                    <table class="basic">
                        <colgroup>
                            <col style="width:20%;">
                            <col style="width:30%;">
                            <col style="width:50%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col">교육과정</th>
                                <th scope="col">구분</th>
                                <th scope="col">교육대상</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" rowspan="2"><strong class="color-008ed4">10일</strong><br>신입교육</th>
                                <td>법인영업부문</td>
                                <td>법인영업 신입 FA</td>
                            </tr>
                            <tr>
                                <td class="left" colspan="2">
                                    <ul>
                                        <li>&middot; 법인운영과 개인사업자 법인전환 컨설팅</li>
                                        <li>&middot; 법인세법, 소득세법, 상법 등 이론과 프로세스 습득</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" rowspan="4"><strong class="color-008ed4">월간</strong><br>정기교육</th>
                                <td>주간정기교육</td>
                                <td>법인영업사업단</td>
                            </tr>
                            <tr>
                                <td class="left" colspan="2">
                                    <ul>
                                        <li>&middot; (월) 세무 집중화 교육</li>
                                        <li>&middot; (수) 금융, 노무, 부동산, 법률교육</li>
                                        <li>&middot; (목) 컨셉 및 상품교육</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>토요 특강</td>
                                <td>법인영업사업단</td>
                            </tr>
                            <tr>
                                <td class="left" colspan="2">
                                    <ul>
                                        <li>&middot; 신규 시장이슈와 영업기법에 대한 지속적 교육</li>
                                        <li>&middot; 법인영업 Best Practice – FA 및 전문가 강좌</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" rowspan="2"><strong class="color-008ed4">신입</strong><br>보수과정</th>
                                <td>4·7 차월<br>교육과정 (1박2일 진행)</td>
                                <td>입사 1년 미만 법인 신입FA</td>
                            </tr>
                            <tr>
                                <td class="left" colspan="2">
                                    <ul>
                                        <li>&middot; 세무 – 임원보수와 세금, 퇴직금 마련 플랜</li>
                                        <li>&middot; 세무 – 자사주, 퇴직금 중간정산</li>
                                        <li>&middot; 세무 – 세무조정계산서와 재무제표의 이해</li>
                                        <li>&middot; 상법 – 정관의 이해</li>
                                        <li>&middot; 상품솔루션 활용 Skill</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" rowspan="2"><strong class="color-008ed4">1박2일</strong><br>체계화과정</th>
                                <td>격월진행</td>
                                <td>법인FA대상</td>
                            </tr>
                            <tr>
                                <td class="left" colspan="2">
                                    <ul>
                                        <li>&middot; 세무조정 계산서 이해</li>
                                        <li>&middot; 세금포트폴리오 & 회계의 기초</li>
                                        <li>&middot; 정관분석</li>
                                        <li>&middot; 크레탑&재무제표 분석</li>
                                        <li>&middot; 세금체계화 가업승계 과정</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" rowspan="2"><strong class="color-008ed4">1박2일</strong><br>심화과정</th>
                                <td>1박2일 심화과정</td>
                                <td>법인FA대상 반기1회</td>
                            </tr>
                            <tr>
                                <td class="left" colspan="2">
                                    <ul>
                                        <li>&middot; 상법 – 경영권방어와 지분구조</li>
                                        <li>&middot; 상품 – 新 시장 재물보험, 배상책임보험 컨설팅</li>
                                        <li>&middot; 세무 – 재무구조개선과 신용도 제고</li>
                                        <li>&middot; 세무 – 가업승계와 세대이전 플랜</li>
                                        <li>&middot; 노무 – 노무컨설팅</li>
                                        <li>&middot; 사례 – 新 마케팅 솔루션</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="info">
            <p class="img"><img src="/img/recruit/recruitFa/img_education02.jpg" alt=""></p>
            <div class="layer_contents">
                <dl>
                    <dt>개인영업교육</dt>
                    <dd>
                        분기마다 수도권과 지방을 구분하여 각기 진행되는 1박2일<br> 심화 과정은 매주 쏟아지는 교육과정을 체계적으로 다시 복습하고 정리하는 기회입니다. <br> 매회 정원이 초과되는 몰입도 심화 과정은 선착순으로 진행<br>됩니다.
                    </dd>
                </dl>
                <button type="button" aria-expanded="false" class="arrow_btn plus">교육과정 상세<img class="ico" src="" data-images-path="/image/svg/plus01.svg"></button>
                <div class="detail" aria-expanded="false">
                    <table class="basic">
                        <colgroup>
                            <col style="width:34%;">
                            <col style="width:33%;">
                            <col style="width:34%;">
                        </colgroup>
                        <thead>
                        <tr>
                            <th scope="col">교육과정</th>
                            <th scope="col">구분</th>
                            <th scope="col">교육대상</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row" rowspan="2"><strong>신입</strong>교육</th>
                            <td>신입교육 과정</td>
                            <td>개인영업 신입 FA</td>
                        </tr>
                        <tr>
                            <td class="left" colspan="2">
                                <ul>
                                    <li>&middot; 경력신입, 무 경력신입 과정</li>
                                    <li>&middot; 입사 3개월 차 교육과정</li>
                                    <li>&middot; 입사 13개월 차 교육과정</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" rowspan="2"><strong>전문가</strong> 과정</th>
                            <td>전문가 과정</td>
                            <td>개인영업사업단</td>
                        </tr>
                        <tr>
                            <td class="left" colspan="2">
                                <ul>
                                    <li>&middot; 생·손보 마스터과정</li>
                                    <li>&middot; DB컨설팅 마스터과정</li>
                                    <li>&middot; 법인컨설팅 심화과정</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" rowspan="2"><strong>커리어강화</strong> 과정</th>
                            <td>커리어 강화과정</td>
                            <td>개인영업사업단</td>
                        </tr>
                        <tr>
                            <td class="left" colspan="2">
                                <ul>
                                    <li>&middot; 육성실장 역량 강화과정</li>
                                    <li>&middot; 지점장 양성과정</li>
                                    <li>&middot; 사업단장 역량 강화과정</li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
    <!-- //교육지원 -->

    <!-- 광고, 홍보 지원 -->
    <section id="falist2" class="advertising stepnum">
        <h3 class="title"><span class="num">02</span>광고 &middot; 홍보 지원</h3>
        <div class="video">
            <button data-src="/video/tvcf/bohumclinic/2020_season2_1.mp4" data-title="&lt;나이스&gt;편" data-type="" type="button" title="영상재생">
                <img class="thumb" src="/img/recruit/recruitFa/img_advertising.jpg" alt="스크린샷">
            </button>            
        </div>
        <dl>
            <dt>유명 배우들 광고모델 발탁</dt>
            <dd>피플라이프는 보다 전문적이고 세련된 기업 브랜드를 구축<br> 하고자 유명 배우를 광고모델로 대대적인 광고를 시행하였<br> 습니다. 이후 주요 일간지를 통한 지속적인 광고 마케팅으로<br> 기업 신뢰도 향상에 힘을 기울이고 있습니다. </dd>
            <dt>Cable, 종편, 홈쇼핑을 넘어 공중파로</dt>
            <dd>2016년 10월 Cable TV와 종편을 통한 인포머셜 광고를<br> 시작으로  공중파 방송까지 피플라이프와 보험클리닉<br> 브랜드 광고를 지속하여 확대할 예정으로, 보험상품을<br> 비교하고 검토하여 가입하려는 모든 고객이 우선 찾는<br> GA 브랜드가 되고자 합니다. </dd>
            <dt>다양한 온라인 마케팅 및 신문광고 진행</dt>
            <dd>네이버, 다음, 구글, SNS, 유튜브 등의 대표 온라인 매체와<br> 신문광고를 통한 전방위 마케팅으로 컨설턴트의 시장 개척을<br> 지원합니다. </dd>
        </dl>
    </section>
    <!-- //광고, 홍보 지원 -->

    <!-- 상품제휴 -->
    <section id="falist3" class="alliance stepnum">
        <h3 class="title"><span class="num">03</span>상품제휴</h3>
        <div class="life">
            <dl>
                <dt>생명보험</dt>
                <dd>
                    국내 생명보험사와 상품 판매 업무 제휴를 맺어 <br>
                    고객중심 맞춤형 상품을 제안합니다.
                </dd>
            </dl>
            <ul>
                <li><img src="" alt=""></li>
            </ul>
        </div>
        <div class="damage">
            <dl>
                <dt>손해보험</dt>
                <dd> 국내 손해보험사와 상품 판매 업무 제휴를 맺어 <br> 화재보험 전문가를 통한 종합 관리 및 손해보험사 상품 <br> 비교를 통해 효율적인 방법을 제안합니다. </dd>
            </dl>
            <ul>
                <li><img src="" alt=""></li>
            </ul>
        </div>
    </section>
    <!-- //상품제휴 -->

    <!-- 전문가 네트워크 -->
    <section id="falist4" class="network stepnum">
        <h3 class="title"><span class="num">04</span>전문가네트워크</h3>
        <p class="img"><img src="/img/recruit/recruitFa/img_network.jpg" alt=""></p>
        <div class="txt_contents">
            <p>
                기업의 경영과 소유 과정에서 발생되는 문제의 해결을 <br>
                위해서는 각 분야에 대해 가장 전문적인 이론과 실전 경험을  <br>
                갖춘 인력이 필요합니다. <br>
                피플라이프는 중소기업 경영지원 컨설팅 분야에서 뛰어난  <br>
                솔루션을 보유한 전문가 그룹과 제휴관계를 맺고 있습니다.
            </p>
            <a href="/intro/Network" class="arrow_btn">자세히보기<img class="ico" src="" data-images-path="/image/svg/arr_right01.svg"></a>
        </div>
    </section>
    <!-- //전문가 네트워크 -->

    <!-- 고객세미나 -->
    <section id="falist5" class="seminar stepnum">
        <h3 class="title"><span class="num">05</span>고객세미나 지원</h3>
        <p class="img"><img src="/img/recruit/recruitFa/img_seminar.jpg" alt=""></p>
        <dl>
            <dt>국내 최대 규모 아마추어 골프 대회, <br> 피플라이프 중소기업 CEO GOLF CLASSIC </dt>
            <dd>대규모 ‘피플라이프 중소기업 CEO Golf Classic’ 을 위해 전국에 다수의 골프장과 제휴하는 등 VIP 세미나를 위한 인프라를 구축하고 있습니다. </dd>
        </dl>
    </section>
    <!-- //고객세미나 -->

    <section id="falist6" class="computational stepnum">
        <h3 class="title"><span class="num">06</span>전산시스템</h3>
        <p class="img"><img src="/img/recruit/recruitFa/img_computational.jpg" alt=""></p>
        <dl>
            <dt>PC, MOBILE 통합 고객관리 시스템 지원</dt>
            <dd>
                자체 개발한 ERP 시스템 PINES를 통해 PC, MOBILE에서<br>
                계약관리, 고객 관리, 성과 및 소득관리를 통합적으로 관리할<br>
                수 있는 편의가 제공되며 최첨단 인슈어테크시스템 지능형<br>
                보장분석 시스템으로 최적의 경쟁력을 지원합니다.
            </dd>
        </dl>
    </section>
    <section id="falist7" class="database stepnum">
        <h3 class="title"><span class="num">07</span>DB제공 시스템</h3>
        <p class="img"><img src="/img/recruit/recruitFa/img_database.jpg" alt=""></p>
        <p class="txt">유명 배우 광고모델 진행, Cable, 종편, 홈쇼핑,<br>공중파 방송 진행과 골프 대회, 고객 초청 세미나를 비롯한<br>VIP 마케팅 및 다양한 온라인 마케팅과 신문광고를 통해<br>고객들이 자발적으로 찾아오는 양질의 시장을 제공함으로써<br>컨설턴트의 활동을 지원합니다.</p>
    </section>
    <section class="bigbang">
        <h3 class="title">피플라이프가 주도하는 GA 빅뱅</h3>
        <div class="img_list">
            <img src="/img/recruit/recruitFa/img_bigbang.png" alt="FA 보험 영업에 관심있는 자 다양한 보험·금융지식을 기반으로 Life Plan을 제시하는 전문가 초기 정착자금 지원, 지점장 보험경력 1년이상/ 사업단장 보험경력 2년이상 조직구축 정착자금지원, 영업 본부장 보험경력 3년이상 조직구축정착자금 + 본부운영비지원">
        </div>
    </section>

    <!-- 채용신청 -->
    <section id="applyForm"  class="section">
        <div class="inner">
            <h3 class="tit_section"><mark>FA 지원하기</mark></h3>
            <li class="row row_carrer_life row_css" data-check-pass="true" data-inbound-prop="lifeCareerYn" data-inbound-value="Y" data-check-comment="생보경력유무를 선택하세요">
                <label class="btn_carrer_life btnCarrerLife">
                    <input name="carrer_life" value="Y" type="radio" data-auto-focus="false" tabindex="-1" checked>
                    <em class="theme_border_color"><!--있음--></em>
                </label>
                <label class="btn_carrer_life btnCarrerLife">
                    <input name="carrer_life" value="N" type="radio" data-auto-focus="false" tabindex="-1">
                    <em class="theme_border_color"><!--없음--></em>
                </label>
            </li>
            <li class="row row_carrer_damage row_css" data-check-pass="true" data-inbound-prop="damageCareerYn" data-inbound-value="Y" data-check-comment="손보경력유무를 선택하세요">
                <label class="btn_carrer_damage btnCarrerDamage">
                    <input name="carrer_damage" value="Y" type="radio" data-auto-focus="false" tabindex="-1" checked>
                    <em class="theme_border_color"><!--있음--></em>
                </label>
                <label class="btn_carrer_damage btnCarrerDamage">
                    <input name="carrer_damage" value="N" type="radio" data-auto-focus="false" tabindex="-1">
                    <em class="theme_border_color"><!--없음--></em>
                </label>
            </li>
            <li class="row row_file row_css" data-check-pass="false" data-inbound-prop="fileAttachId" data-inbound-value="" data-check-comment="입사지원서를 업로드하세요">
                <button type="button"><img class="ico" src="" data-images-path="/image/svg/clip01.svg"></button>
                <span class="file_name"></span>
                <input type="file" class="hidden">
            </li>
            <div class="loadForm"></div>
        </div>
    </section>
    <!-- //채용신청 -->

    <!-- 채용문의 -->
    <section class="question">
        <div class="box">
            <h3>채용문의</h3>
            <a href="tel:02-6906-9102">02.6906-9102</a>
            <p>자세한 사항은 인사담당자가 안내 드리겠습니다.</p>
        </div>
        <span class="txt1">보험영업, 한 단계 도약을 원하신다면?</span>
        <em class="txt2">피플라이프와 함께하세요!<br><strong>당신이 그 기회의 주인공 입니다.</strong></em>
        <em class="txt3">※ 본 화면은 보험설계사를 모집하는 것으로 정규 또는 비정규 직원채용과 무관합니다.</em>
    </section>
    <!-- //채용문의 -->

    <button class="floting anchor" data-target="#applyForm" type="button"><img src="/img/recruit/recruitEfa/img_floting.png" alt="입사지원"></button>
</main>
<!-- /main -->
</template>

<style scoped lang="scss">
    @import '@/assets/scss/recruit/recruitFa.scss';
</style>

<script>
    export default {}
</script>