<template>
<!-- main -->
<main id="container" class="newScrollAction" data-name="fa">
    <h1 class="screen_out">개인영업(FA)</h1>

    <!-- 메인비주얼 -->
    <section class="visual">
        <div class="txt_box">
            <em><img :src="imgPath+'fa_logo.png'" alt="FA"></em>
            <p>한층 풍요로운 라이프 스타일을 위한</p>
            <strong>보험 전문 컨설팅</strong>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- FA소개 -->
    <section class="information">
        <h2 class="title"><img :src="imgPath+'fa_title.png'" alt="clinic"></h2>
        <div class="exp">
            <div>
                <p class="img"><img :src="imgPath+'pic_fa_analysis.png?v=230116'" alt=""></p>
                <p class="tit">
                    안정적인 자산관리 테라피<br>
                    <strong>「  FA  」</strong>
                </p>
                <p class="txt">
                    보험을 통한 보장이라는 안전장치를 기반으로,<br>
                    체계적 종합 자산관리를 통한 인생 전반을<br>
                    설계하고 있습니다.
                    <br><br>
                    합리적 금융소비 활동을 위한 최적화된<br>
                    디지털 알고리즘을 제공하며, 심층 평가와<br>
                    분석 기반의 맞춤 컨설팅을 통해 고객 자산관리<br>
                    테라피를 제안합니다.
                </p>
            </div>
            <!-- <a href="//m.bohumclinic.com/visit" target="_blank" class="arrow_btn">FA 바로가기</a> -->
        </div>

    </section>
    <!-- //FA소개 -->

    <!-- 차별화 서비스  -->
    <section class="differentiation">
        <h3 class="title">피플라이프 FA 차별화 서비스</h3>
        <div class="step">
            <ol>
                <li>
                    <img :src="imgPath+'pic_fa_step01.jpg'" alt="">
                    <div class="box">
                        <strong>「 고객 맞춤형 방문 서비스 제공 」</strong>
                        <p> 365일 언제 어디서나!<br> 고객님이 요청한 시간, 장소로 FA가 직접 방문하여 <br> 1:1 맞춤형 컨설팅을 진행합니다. 고객님의 얘기를 <br> 직접 듣고 최적의 솔루션을 제공합니다. </p>
                    </div>
                </li>
                <li>
                    <img :src="imgPath+'pic_fa_step02.jpg?v=23012012'" alt="">
                    <div class="box">
                        <strong>「 무료 상담 서비스 」</strong>
                        <p> 기존 보험 점검, 리모델링을 위한 상담 비용 ZERO! <br> 피플라이프 전문가가 보험 점검부터 관리까지 무료로 <br> 전담하며 상담이 완료된 후 맞춤보장분석 보고서를  <br> 무료로 제공합니다. </p>
                    </div>
                </li>
                <li>
                    <img :src="imgPath+'pic_fa_step03.jpg'" alt="">
                    <div class="box">
                        <strong>「 주요 보험사 상품 비교 분석 」</strong>
                        <p> 피플라이프는 주요 보험사 제휴로<br> 상품 포트폴리오를 실시간 비교<br> 분석하여 고객에게 유리한 상품을 추천합니다.<br> </p>
                    </div>
                </li>
                <li>
                    <img :src="imgPath+'pic_fa_step04.jpg'" alt="">
                    <div class="box">
                        <strong>「 업계 전문가 그룹 」</strong>
                        <p> 단순한 보험 비교를 넘어 이론과 실전 경험을 갖춘 <br> 피플라이프 전문가 네트워크를 통해 생애 전반의 라이프 <br> 케어 및 자산관리 컨설팅 등 고객님의 풍요로운 <br> 미래를 위해 노력합니다. </p>
                    </div>
                </li>
            </ol>
        </div>
    </section>
    <!-- //차별화 서비스  -->

    <!-- 전문가 네트워크 -->
    <networkTab />
    <!-- //전문가 네트워크 -->

    <!-- 디지털 알고리즘 -->
    <section id="algorithm">
        <div class="inner">
            <h3 class="title"> 최적화된 보험상품판매를 위한<br> 디지털 알고리즘 </h3>
            <p class="img">
                <img :src="imgPath+'pic_fa_algorithm01.png'" alt="">
                <img :src="imgPath+'pic_fa_algorithm02.png'" alt="">
            </p>
            <div class="box top">
                <h4 class="tit">보험클리닉 APP</h4>
                <div class="btn_layout">
                    <a href="//play.google.com/store/apps/details?id=kr.co.peoplelife.panc" target="_blank"><img :src="imgPath+'btn_gp.jpg'" alt="구글플레이 바로가기"></a>
                    <a href="//apps.apple.com/kr/app/%EB%B3%B4%ED%97%98%ED%81%B4%EB%A6%AC%EB%8B%89/id1373790260" target="_blank"><img :src="imgPath+'btn_as.jpg'" alt="앱스토어 바로가기"></a>
                </div>
                <ul class="list_graph top">
                    <li class="item_graph"><p><span>내 보험 조회<br>및 자가진단<br>서비스</span></p></li>
                    <li class="item_graph"><p><span>보험금<br>청구업무 지원</span></p></li>
                    <li class="item_graph"><p><span>FA 지정<br> 등록 및 문의</span></p></li>
                    <li class="item_graph"><p><span>연금<br>조회</span></p></li>
                </ul>
            </div>
            <div class="box bottom">
                <h4 class="tit">어시스트(@ssit)</h4>
                <ul class="list_graph bottom">
                    <li class="item_graph"><p><span>APP에서<br>수집한<br>고객정보 통한<br>보장분석</span></p></li>
                    <li class="item_graph"><p><span>상품요악 및<br>비교설계</span></p></li>
                    <li class="item_graph"><p><span>보험사 약관,<br>공시이율,<br>변액수익률<br>확인</span></p></li>
                    <li class="item_graph"><p><span>상품라인업,<br>제안서 총서,<br>통계확인</span></p></li>
                </ul>
            </div>
        </div>
    </section>
    <!-- //디지털 알고리즘 -->

    <!-- 보험컨설팅  -->
    <bohumConsulting />
    <!-- //보험컨설팅  -->

    <!-- 프로세스 -->
    <section class="process">
        <h3 class="title">고객 맞춤형 컨설팅 프로세스</h3>
        <p class="image"><img :src="imgPath+'pic_fa_process_img01.jpg'" alt=""></p>
        <div class="process_slide_contents">
            <div class="description">
                <ul>
                    <li data-index="1" style="display: block;">
                        <strong>STEP1. 상담 신청</strong>
                        <span>
                            상담을 원하시는 고객님의 기본적인 정보를 등록해 주세요.
                        </span>
                    </li>
                    <li data-index="2">
                        <strong>STEP2. 보험전문가 FA 지정</strong>
                        <span>
                            수많은 경험과 실전 지식을 보유한 고객 맞춤 보험 전문가 FA가 지정됩니다.
                        </span>
                    </li>
                    <li data-index="3">
                        <strong>STEP3. 고객상담(고객분석)</strong>
                        <span>
                            고객님이 현재 보유한 보험의 보장 및 보험료가 적정한지 확인합니다.
                            투자 성향, 재무 상황 및 미래의 계획을 상담하고 고객님의 보험 및 자산을 분석합니다.
                        </span>
                    </li>
                    <li data-index="4">
                        <strong>STEP4. 상품 비교 및 분석</strong>
                        <span>
                            피플라이프만의 지능형 보장분석 시스템 어시스트(@ssit)를 통해
                            주요 보험사 상품을 비교 분석합니다.
                        </span>
                    </li>
                    <li data-index="5">
                        <strong>STEP5. 자산관리 컨설팅 지원</strong>
                        <span>
                            CFP팀이 고객님의 증권을 분석하고 금융 연구소에서
                            보험 비교를 넘어 생애 전반의 라이프 케어 및 자산관리 컨설팅을 지원합니다.
                        </span>
                    </li>
                    <li data-index="6">
                        <strong>STEP6. 맞춤 포트폴리오 제안</strong>
                        <span>
                            차별화된 상품 포트폴리오로 ‘보험사’가 아닌<br>
                            고객님에게 유리한 상품만을 추천한 맞춤 포트폴리오를 제안합니다.
                        </span>
                    </li>
                    <li data-index="7">
                        <strong>STEP7. 상담 후 평생관리</strong>
                        <span>
                            보험금 청구 시스템 및 지속 관리를 통해 고객 편의성을 증대시키고,
                            정기적인 검진을 통해 고객 만족을 위해 노력합니다.
                        </span>
                    </li>
                </ul>
            </div>
            <div class="swiper-container">
                <ul class="swiper-wrapper">
                    <li class="swiper-slide">
                        <strong>STEP 01</strong>
                        <span>상담 신청</span>
                    </li>
                    <li class="swiper-slide">
                        <strong>STEP 02</strong>
                        <span>보험전문가<br> FA 지정</span>
                    </li>
                    <li class="swiper-slide">
                        <strong>STEP 03</strong>
                        <span>고객상담<br>(고객분석)</span>
                    </li>
                    <li class="swiper-slide">
                        <strong>STEP 04</strong>
                        <span>상품 비교 및<br> 분석</span>
                    </li>
                    <li class="swiper-slide">
                        <strong>STEP 05</strong>
                        <span>자산관리<br> 컨설팅 지원</span>
                    </li>
                    <li class="swiper-slide">
                        <strong>STEP 06</strong>
                        <span>맞춤 포트폴리오<br> 제안</span>
                    </li>
                    <li class="swiper-slide">
                        <strong>STEP 07</strong>
                        <span>상담 후<br> 평생관리</span>
                    </li>
                </ul>
            </div>
            <div class="counter">
                <span class="num">01</span> / <span class="total"></span>
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </section>
    <!-- //프로세스 -->

    <!-- 상담신청 -->
    <section id="freeConsult" class="section">
        <div class="inner">
            <h3 class="tit_section"> 보험전문가가 찾아오는<br> 보험 컨설팅 신청하기 </h3>        
            <ul class="list_about">
                <li class="item_about">
                    <div class="box_ico">
                        <img :src="imgPath+'icon_fa_application01.png'" alt="">
                    </div>
                    <p class="tit"> 주요 보험사 상품 비교 </p>
                </li>
                <li class="item_about">
                    <div class="box_ico">
                        <img :src="imgPath+'icon_fa_application02.png'" alt="">
                    </div>
                    <p class="tit"> 어시스트(@ssit)로<br> 원스톱 AI통합지원 시스템 지원 </p>
                </li>
                <li class="item_about">
                    <div class="box_ico">
                        <img :src="imgPath+'icon_fa_application03.png'" alt="">
                    </div>
                    <p class="tit"> 보험 점검부터 관리까지<br>모든 서비스 무료제공 </p>
                </li>
            </ul>
            <div class="loadForm"></div>
            <!-- <li class="row row_talktime row_css clearfix" data-type="talkTime" data-check-pass="true" data-check-comment="연락 가능한 시간을 선택해주세요" data-inbound-prop="talk-time">
                <label class="select_wrap">
                    <select name="consult" class="openSans consult select_css">
                        <option value="언제나 통화가능" selected>언제나 통화가능</option>
                        <option value="오전 09:00 ~ 10:00">오전 09:00 ~ 10:00</option>
                        <option value="오전 10:00 ~ 11:00">오전 10:00 ~ 11:00</option>
                        <option value="오전 11:00 ~ 12:00">오전 11:00 ~ 12:00</option>
                        <option value="오후 01:00 ~ 02:00">오후 01:00 ~ 02:00</option>
                        <option value="오후 02:00 ~ 03:00">오후 02:00 ~ 03:00</option>
                        <option value="오후 03:00 ~ 04:00">오후 03:00 ~ 04:00</option>
                        <option value="오후 04:00 ~ 05:00">오후 04:00 ~ 05:00</option>
                        <option value="오후 05:00 ~ 06:00">오후 05:00 ~ 06:00</option>
                    </select>
                    <i data-images-path="/image/common/icoSelect.png"></i>
                </label>
            </li> -->
        </div>
    </section>
    <!-- //상담신청 -->

    <!-- 안내사항 -->
    <section id="about" class="section">
        <div class="inner">
            <p class="desc_section">보험영업, 한 단계 도약을 원하신다면?</p>
            <h3 class="tit_section">피플라이프와 함께하세요!<br><strong>다음 성공의 주인공은 당신 입니다.</strong></h3>
            <a class="link_about" href="/recruit/recruitFa"><span>개인영업(FA) 인재채용 바로가기</span><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></a>
        </div>
    </section>
    <!-- //안내사항 -->
</main>
<!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/sales/fa/'
        }
    }
}
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/sales/fa.scss';
</style>
