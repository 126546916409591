<template>
<!-- main -->
<main id="container" class="newScrollAction" data-name="company">
    <h1 class="screen_out">회사소개</h1>

    <section class="visual section">
        <strong>금융판매 전문 플랫폼으로의 도약,<br> 피플라이프</strong>
        <em>
            피플라이프는 영업채널 다양화를 통한 <br>
            고객 특성별 맞춤 판매를 지향하며,<br>
            다양한 금융소비자가 만족할 수 있도록 <br>
            전 국민 대상 금융판매채널을 구축하고 있습니다.
        </em>
    </section>

    <section class="representation section">
        <div class="step1">
            <i class="ceo"><span>CEO</span></i>
            <dl>
                <dt>법인컨설팅</dt>
                <dd>
                    CEO &middot; 임직원 대상<br>
                    전문가 네트워크 기반 법인 컨설팅 제공
                </dd>
            </dl>
        </div>
        <div class="step2">
            <i>FA</i> 
            <dl>
                <dt>개인영업(FA)</dt>
                <dd>
                    개인고객 대상 금융상품<br>
                    비교분석 &middot; 재무설계 서비스
                </dd>
            </dl>
        </div>
        <div class="step3">
            <i><img src="/img/intro/company/icon_representation1.png" alt=""></i>
            <dl>
                <dt>보험클리닉</dt>
                <dd>
                    오프라인 보험샵<br>
                    원스톱 보험 비교 추천 서비스
                </dd>
            </dl>
        </div>
        <div class="step5">
            <i>TFA</i>
            <dl>
                <dt>TM영업(TFA)</dt>
                <dd>
                    전화상담 고객 대상 자동 보장분석<br>
                    시스템 기반 언택트 텔레마케팅 서비스
                </dd>
            </dl>
        </div>
    </section>

    <section class="introduce section">
        <h3 class="title">회사소개</h3>
        <div class="image_thumb">
            <img class="thumb" src="/img/intro/company/introduce_thumb.jpg" alt="회사소개 이미지">
        </div>
        <!-- <button class="videoLayer" data-src="https://www.youtube.com/embed/9HXmlfVaKe0" data-title="피플라이프 금융판매전문회사로 도약" data-type="youtube" type="button" title="영상재생">
            <img class="thumb" src="https://img.youtube.com/vi/9HXmlfVaKe0/maxresdefault.jpg" alt="스크린샷">
        </button> -->
        <div class="gtay_box">
            <strong>당신에게 필요한 모든 순간, 전방위적으로!</strong>
            <p>
                보장분석을 바탕으로 한 보험상품의 비교추천부터<br>
                효율적인 개인 자산의 관리, 자산가의 증여와 상속,<br>
                절세 카운슬링, 중소기업의 경영 효율화에 이르기까지<br>
                당신의 삶에 필요한 순간, 늘 고객 곁에 마주하겠습니다.
            </p>
        </div>
    </section>

    <section id="ceo" class="section">
        <div class="inner">
            <h3 class="title">CEO 인사말</h3>
            <div class="contents">
                <strong class="tit">
                    고객의 삶에 <b>필요한 순간</b> 늘 함께 하며,<br>
                    고객의 삶에 <b>새로운 가치</b>를 더하겠습니다.
                </strong>
                <div class="photo_zone">
                    <img src="/img/intro/company/ceo_photo.png" alt="">
                    <em>
                        <span>피플라이프(주)</span><br>
                        <span>대표이사 </span><strong class="owner" data-peoplelife="owner"></strong>
                        <img src="/img/intro/company/ceo_sign.png" alt="">
                    </em>
                </div>
                <p class="comment">
                    <span>
                        피플라이프 홈페이지를 방문해주신 고객님께 감사드립니다.
                    </span>
                    <span>
                        피플라이프는 2003년 설립 이래 시장을 선도하는 실험과<br>
                        도전정신으로 정주행하며 고객들과 함께 성장해왔습니다.
                    </span>
                    <span>
                        특정 금융사에 속한 판매채널이 아니라 고객에게 필요한<br>
                        최적의 금융상품을 추천하고, 고객이 가장 편리하고 정확한<br>
                        구매 경험을 할 수 있는 환경과 서비스를 제공하고 고객 삶에<br>
                        가치를 더하는 금융유통플랫폼 구축이라는 비전을 위해<br>
                        매일 고민하고 있습니다.
                    </span>
                    <span>
                        2023년 한화그룹 계열사로 편입되면서<br>
                        한화금융의 디지털 역량과 <b>함께 멀리</b>의 그룹경영 이념을<br>
                        바탕으로 한 제2의 변화와 혁신으로 고객과 가장 가까이에<br>
                        있는 판매전문회사로 거듭 나겠습니다.
                    </span>
                    <span>
                        앞으로도 피플라이프 전임직원은 본립도생(本立道生)의<br>
                        자세로 비즈니스 과정에서 소비자보호를 강화하고 공정거래,<br>
                        부패방지 등 윤리·준법 경영을 지속 실천하는 최고의<br>
                        판매전문회사가 되도록 최선의 노력을 다하겠습니다.
                    </span>
                    <span>
                        감사합니다.
                    </span>
                </p>
            </div>
        </div>
    </section>

    <!-- 기업이념 -->
    <section class="ideology section">
        <h3 class="title">기업이념</h3>
        <p class="description">
            신뢰와 열정이라는 책임 가치와 전문성을 바탕으로<br>
            고객 생애 전반의 성공을 위한 서비스를 제공합니다.
        </p>
        <ul class="list">
            <li>
                <i>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.83 31.26" class="icon_line">
                        <g>
                            <g>
                                <polygon points="0 0 0 27.56 23.28 27.56 23.28 26.06 1.5 26.06 1.5 1.5 35.33 1.5 35.33 26.06 31.77 26.06 31.77 27.56 36.83 27.56 36.83 0 0 0" />
                                <path d="M31.29,21.3h1.36V19.8H31.29a3.71,3.71,0,0,0-.56-1.34l1-1-1.06-1.06-1,1a3.58,3.58,0,0,0-1.35-.56V15.48h-1.5v1.36a3.61,3.61,0,0,0-1.34.56l-1-1-1.06,1.06,1,1a3.71,3.71,0,0,0-.56,1.34H22.5v1.5h1.36a3.71,3.71,0,0,0,.56,1.34l-1,1,1.06,1.06.15-.16v6.75l2.9-2.35,2.9,2.35V24.51l.16.16,1.06-1.06-1-1A3.71,3.71,0,0,0,31.29,21.3Zm-3.72-3a2.29,2.29,0,1,1-2.29,2.29A2.29,2.29,0,0,1,27.57,18.26Zm0,8.73-1.4,1.13V24.07a3.87,3.87,0,0,0,1.4.27,3.79,3.79,0,0,0,1.4-.27v4.05Z" />
                                <rect x="8.71" y="5.54" width="19.4" height="1.5" />
                                <rect x="27.1" y="11.08" width="3.34" height="1.5" />
                                <rect x="15.81" y="11.08" width="9.12" height="1.5" />
                                <rect x="6.02" y="11.08" width="7.11" height="1.5" />
                                <rect x="16.35" y="14.73" width="5.72" height="1.5" />
                                <rect x="6.02" y="14.73" width="7.76" height="1.5" />
                                <rect x="10.92" y="18.38" width="9.23" height="1.5" />
                                <rect x="6.02" y="18.38" width="2.69" height="1.5" />
                            </g>
                        </g>
                    </svg>
                </i>
                <strong>신뢰</strong>
                <span>
                    고객의 문제에 대하여<br>
                    비효율과 부정을<br>
                    용납하지 않습니다.
                </span>
            </li>
            <li>
                <i>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.04 44.62" class="icon_line">
                        <g>
                            <g>
                                <path class="cls-1" d="M28.08,27.62a12.5,12.5,0,1,0-18.12,0V35h.28l3.36,6.88h2a3.52,3.52,0,0,0,6.89,0h2L27.8,35h.28Zm-1.5,5.84H11.46V28.08H26.58ZM19,8a11,11,0,0,1,8,18.56H11A11,11,0,0,1,19,8ZM13,37.25H25l-.39.8H13.42Zm6,5.87a2,2,0,0,1-1.89-1.28h3.78A2,2,0,0,1,19,43.12Zm3.53-2.78h-8l-.39-.79h9.74l-.39.79Zm3.19-4.59H12.3L11.91,35H26.13Z" />
                                <path class="cls-1" d="M11,23.31a9,9,0,0,0,1.44,2l1.08-1a8.1,8.1,0,0,1-1.2-1.65Z" />
                                <path class="cls-1" d="M11.46,19a7.56,7.56,0,0,1,5.65-7.31l-.38-1.45A9.05,9.05,0,0,0,10,19a10.22,10.22,0,0,0,.1,1.35l1.49-.22A7.08,7.08,0,0,1,11.46,19Z" />
                                <rect class="cls-1" x="18.27" width="1.5" height="4.69" />
                                <rect class="cls-1" x="9.93" y="2.23" width="1.5" height="4.69" transform="translate(-0.86 5.95) rotate(-30)" />
                                <rect class="cls-1" x="3.83" y="8.34" width="1.5" height="4.69" transform="translate(-6.96 9.3) rotate(-60)" />
                                <rect class="cls-1" y="18.27" width="4.69" height="1.5" />
                                <rect class="cls-1" x="2.23" y="26.61" width="4.69" height="1.5" transform="translate(-13.07 5.96) rotate(-30)" />
                                <rect class="cls-1" x="25.02" y="3.83" width="4.69" height="1.5" transform="translate(9.71 25.98) rotate(-60)" />
                                <rect class="cls-1" x="31.12" y="9.93" width="4.69" height="1.5" transform="translate(-0.86 18.17) rotate(-30.01)" />
                                <rect class="cls-1" x="33.36" y="18.27" width="4.69" height="1.5" />
                                <rect class="cls-1" x="32.71" y="25.02" width="1.5" height="4.69" transform="translate(-6.96 42.66) rotate(-60)" />
                            </g>
                        </g>
                    </svg>
                </i>
                <strong>열정</strong>
                <span>
                    고객의 이익을 최우선으로<br>
                    하여 열정적으로<br>
                    최선을 다합니다.
                </span>
            </li>
            <li>
                <i>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.27 32.12" class="icon_line">
                        <g>
                            <g>
                                <path class="cls-1" d="M37.5,0a3.31,3.31,0,0,0-3.25,3.37,3.47,3.47,0,0,0,.59,1.94l-7.18,8.26a3.09,3.09,0,0,0-3.46.19L17.5,7.61A3.42,3.42,0,0,0,17.92,6a3.25,3.25,0,1,0-6.5,0,3.39,3.39,0,0,0,.16,1l-6,3.66A3.21,3.21,0,0,0,3.25,9.6a3.38,3.38,0,0,0,0,6.75A3.32,3.32,0,0,0,6.5,13a3.41,3.41,0,0,0-.17-1l6-3.65a3.18,3.18,0,0,0,2.36,1.06,3.11,3.11,0,0,0,1.88-.64l6.69,6.16a3.5,3.5,0,0,0-.41,1.61,3.25,3.25,0,1,0,6.5,0,3.44,3.44,0,0,0-.59-1.93L35.92,6.3a3.06,3.06,0,0,0,1.58.45,3.32,3.32,0,0,0,3.25-3.38A3.32,3.32,0,0,0,37.5,0ZM3.25,14.85a1.88,1.88,0,0,1,0-3.75,1.88,1.88,0,0,1,0,3.75Zm11.42-7A1.84,1.84,0,0,1,12.86,6a1.81,1.81,0,1,1,3.61,0A1.84,1.84,0,0,1,14.67,7.87Zm11.41,10.5a1.87,1.87,0,1,1,1.81-1.87A1.84,1.84,0,0,1,26.08,18.37ZM37.5,5.25a1.84,1.84,0,0,1-1.81-1.88,1.81,1.81,0,1,1,3.61,0A1.84,1.84,0,0,1,37.5,5.25Z" />
                                <path class="cls-1" d="M14.6,15.46,1.91,22.73v9.39h37V14L26.33,26Zm22.84,2V30.62h-34v-7l11-6.3,12,10.76Z" />
                            </g>
                        </g>
                    </svg>
                </i>
                <strong>성공</strong>
                <span>
                    고객의 경제적, 사회적<br>
                    성공을 목표로 합니다.
                </span>
            </li>
            <li>
                <i>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.21 33.84" class="icon_line">
                        <g>
                            <g>
                                <polygon class="cls-1" points="26.29 32.34 1.5 32.34 1.5 1.5 26.29 1.5 26.29 7.5 27.79 7.5 27.79 0 0 0 0 33.84 27.79 33.84 27.79 20.49 26.29 20.49 26.29 32.34" />
                                <path class="cls-1" d="M32.09,5.88H31L17,20,14.06,25.8H5.4v1.5H15.46v-.36l5.47-2.66L35.21,10.05V9ZM18.54,20.49,29.17,9.85l2.06,2.06L20.6,22.54Zm-.85,1.27,1.63,1.63-3.17,1.54Zm14.6-10.91L30.23,8.79l1.33-1.32,2.06,2.05Z" />
                                <rect class="cls-1" x="5.4" y="15.14" width="8.2" height="1.5" />
                                <rect class="cls-1" x="5.4" y="20.47" width="9.86" height="1.5" />
                                <rect class="cls-1" x="5.4" y="9.81" width="11.72" height="1.5" />
                            </g>
                        </g>
                    </svg>
                </i>
                <strong>전문성</strong>
                <span>
                    고객에게 최적의 서비스를<br>
                    제공하기 위하여<br>
                    전문성을 갖춥니다.
                </span>
            </li>
        </ul>
    </section>
    <!-- //기업이념 -->

    <!-- CI 소개 -->
    <section id="ci" class="section">
        <div class="inner">
            <h3 class="tit_section">CI</h3>
            <p class="desc_section">CI는 퍼즐의 형태를 띤 사람의 모습으로<br>상호 유기적인 관계를 형상화하였습니다.<br>이는 곧 <strong>사람과 사람, 고객과 기업의 화합</strong>을 의미합니다.<br><br>서로 안고 있는 이미지를 통해<br><strong>긍정적이고 건강하게 더불어 살아가는<br>상생의 관계를 표현</strong>하였습니다. </p>                            
            <ul class="list_type">
                <li class="item_type">
                    <p class="tit">KOREAN (Main)</p>
                    <div class="box_logo">
                        <img src="" data-images-path="/image/logo/peoplelife_basic.svg" alt="">
                    </div>                    
                </li>
                <li class="item_type">
                    <p class="tit"> </p>
                    <div class="box_logo">
                        <img src="/img/intro/company/ci_logo2.jpg" alt="로고">
                    </div>
                </li>
                <li class="item_type">
                    <p class="tit">Single Logo</p>
                    <div class="box_logo">
                        <img src="" data-images-path="/image/logo/peoplelife_symbol.svg" alt="">
                    </div>
                </li>
            </ul>
            <div class="info">
                <strong class="color">
                    <i class="b"></i>
                    <i class="g"></i>
                    <i class="o"></i>
                    색상규정
                </strong>
                <p class="txt"> 피플라이프 CI에 사용된 색상은 심볼마크, 로고타입과 함께 피플라이프의 이미지를 전달하는 핵심 요소로써 화합의 의지를 담고 있습니다. </p>
            </div>
            <div class="system isAppear">                
                <ul class="list_system">
                    <li class="item_system">
                        <p class="tit">BC_BLUE</p>
                        <p class="cmyk"><strong>CMYK</strong>C100 / M26 / Y0 / K0</p>
                        <p class="rgb"><strong>RGB</strong>R0 / G135 / B210</p>
                    </li>
                    <li class="item_system">
                        <p class="tit">BC_GREEN</p>
                        <p class="cmyk"><strong>CMYK</strong>C53 / M0 / Y100 / K0</p>
                        <p class="rgb"><strong>RGB</strong>R135 / G195 / B35</p>
                    </li>
                    <li class="item_system">
                        <p class="tit">BC_GRAY</p>
                        <p class="cmyk"><strong>CMYK</strong>C0 / M50 / Y100 / K0</p>
                        <p class="rgb"><strong>RGB</strong>R245 / G150 / B0</p>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- //CI 소개 -->
    
    <!-- 오시는 길 -->
    <section class="way">
        <h3 class="title">오시는길</h3>
        <div id="map"></div>
        <div class="info">
            <div class="address">
                <strong>주소</strong>
                <ul>
                    <li>도로명 : <span data-peoplelife="address"></span> <span data-peoplelife="addressDetail"></span></li>
                    <li>지&nbsp;&nbsp;&nbsp;번 : (<span data-peoplelife="postNumber"></span>) <span data-peoplelife="addressOldType"></span> <span data-peoplelife="addressDetailOldType"></span></li>
                </ul>
            </div>
            <p class="tel">
                <strong>대표전화</strong>
                <a data-peoplelife="callCenter"></a>
            </p>
        </div>
    </section>
    <!-- //오시는 길 -->

    <!-- 브러쉬 -->
    <section class="brochure">
        <h3 class="design_title"><strong>PEOPLELIFE</strong> Brochure <span>재무보좌관 피플라이프</span></h3>
        <p class="txt"> 금융정보의 비대칭을 해소할 수 있는<br> 금융상품 판매 플랫폼이 피플라이프의 미래입니다.<br> 금융 유통회사로의 비전을 위해 새로운 금융 유통 플랫폼을<br> 만들고 또 개선해 나가겠습니다. </p>
        <div class="img"><img src="/img/intro/company/pic_brochure.png" alt="brochure"></div>
        <a class="button" href="" data-images-path="/pdf/peoplelife_brochure/2023.pdf" target="_blank" download>자세히보기</a>
    </section>
    <!-- //브러쉬 -->
</main>
<!-- /main -->
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/intro/company.scss';
</style>