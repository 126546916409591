<template>
<!-- main -->
<main id="container" class="newScrollAction" data-name="recruitBohum">
    <h1 class="screen_out">보험클리닉 채용</h1>

    <!-- 비쥬얼 -->
    <section id="visual">
        <div class="visual_inner">
            <div class="logo_wrap">
                <div class="logo_inner">
                    <h1 class="logo">
                        <img :src="imgPath+'clinic_logo.png'" alt="보험클리닉 피플라이프">
                    </h1>
                    <!-- <div class="brand_banner">
                        <button><img :src="imgPath+'banner_awards_tp5.png'" alt="브랜드평판 6개월 연속 1위! 자세히보기"></button>
                    </div> -->
                </div>
            </div>
            <div class="main_text">
                <h2><p> 신입/경력 <br> 상담매니저 공개채용 </p> </h2>
                <div class="receipt_time">
                    <p>접수기간 : 상시채용</p>                    
                </div>
            </div>
        </div>
    </section>
    <!-- //비쥬얼 -->

    <!-- 채용설명회 -->
    <!-- <section id="youtobeBanner">
        <div class="youtobe_banner">
            <a href="http://asq.kr/7fUZxyrTwjml" target="_blank">
                <img :src="imgPath+'banner_recruit_mo.png'" alt="피플라이프(주) 보험클리닉 온라인 라이브 채용설명회 2021.09.13(월)) 14:00시">
            </a>
        </div>
    </section> -->
    <!-- //채용설명회 -->

    <!-- 상담매니저 이야기 -->
    <!-- <section id="bohumStory" class="section">
        <div class="inner">
            <h3 class="tit_section">보험클리닉 상담매니저에<br>지원할 수 밖에 없는 이유</h3>
            <p class="desc_section">소득도 안정감도 모두 다 책임지니까!<br>영상으로 확인하고 지금 바로 지원하세요!</p>
            <ul class="list_video">
                <li class="item_video">
                    <button data-src="https://www.youtube.com/embed/UhCVSTTaJ1U" data-title="" data-type="youtube" type="button" title="영상재생">
						<img class="thumb" src="https://img.youtube.com/vi/UhCVSTTaJ1U/maxresdefault.jpg" alt="스크린샷">
                        <p class="tit">상담매니저의 하루편</p>
					</button>
                    <p class="tag">#상담매니저의 하루는 어떨까요?<br>#수고했어 오늘도 </p>
                </li>
                <li class="item_video">
                    <button data-src="https://www.youtube.com/embed/gJOjLefLRXA" data-title="" data-type="youtube" type="button" title="영상재생">
						<img class="thumb" src="https://img.youtube.com/vi/gJOjLefLRXA/maxresdefault.jpg" alt="스크린샷">
                        <p class="tit">워라밸편</p>
					</button>
                    <p class="tag">#눈치게임 없는 퇴근시간<br>#일과 삶의 균형</p>
                </li>
                <li class="item_video">
                    <button data-src="https://www.youtube.com/embed/GCY6upao2dc" data-title="" data-type="youtube" type="button" title="영상재생">
						<img class="thumb" src="https://img.youtube.com/vi/GCY6upao2dc/maxresdefault.jpg" alt="스크린샷">
                        <p class="tit">영업지원편</p>
					</button>
                    <p class="tag">#고객이 직접 매장으로 방문해주시니까</p>
                </li>
                <li class="item_video">
                    <button data-src="https://www.youtube.com/embed/BS-kZspzJUc" data-title="" data-type="youtube" type="button" title="영상재생">
						<img class="thumb" src="https://img.youtube.com/vi/BS-kZspzJUc/maxresdefault.jpg" alt="스크린샷">
                        <p class="tit">마~ 이번에는 <br> 부산이다!!편</p>
					</button>
                    <p class="tag">#부산에 머가 생겼다고? <br>#해운대점 상담문의 쓰나미! </p>
                </li>
                <li class="item_video">
                    <button data-src="https://www.youtube.com/embed/KLBQYbLpeFw" data-title="" data-type="youtube" type="button" title="영상재생">
						<img class="thumb" src="https://img.youtube.com/vi/KLBQYbLpeFw/maxresdefault.jpg" alt="스크린샷">
                        <p class="tit">상담매니저 <br> 일일 체험기 편</p>
					</button>
                    <p class="tag">#늬들이 보험클리닉 상담매니저를 알아?</p>
                </li>
                
            </ul>
        </div>
    </section> -->
    <!-- //상담매니저 이야기 -->

    <!-- 인터뷰 -->
    <!-- <section class="interview">
        <div class="visual">
            <div data-class="v0" class="active">
                <span class="point">보험클리닉 <b>범계역점</b></span>
                <span class="manager"><b>안은지</b> 상담매니저</span>
                <img :src="imgPath+'img_interview01.png'" alt="">
            </div>
            <div data-class="v1">
                <span class="point">보험클리닉 <b>해운대점</b></span>
                <span class="manager"><b>허미경</b> 상담매니저</span>
                <img :src="imgPath+'img_interview02.png'" alt="">
            </div>
            <div data-class="v2">
                <span class="point">보험클리닉 <b>탄방점</b></span>
                <span class="manager"><b>홍지현</b> 상담매니저</span>
                <img :src="imgPath+'img_interview03.png'" alt="">
            </div>
        </div>
        <div class="swiper-container">
            <ul class="swiper-wrapper">
                <li class="swiper-slide">
                    고객의 불편함을 보듬어주고 또 해결하기 <br>
                    위해 노력하다 보면 제가 그 고객의 또 다른 <br>
                    동반자로서의 역할을 하게 될 것이라고 생각해요. <br>
                    그런 날이 올 수 있도록 최선을 다할 것이고요. <br>
                    또 일과 가정을 리드하는 커리어우먼으로서 <br>
                    당당하게 제 꿈을 펼치고 싶어요.
                </li>
                <li class="swiper-slide">
                    보험설계사 아닌 상담 매니저이기 때문에 <br>
                    보험설계사들이 상담하면서 미처 말하지 못하는 <br>
                    부분도 고객의 입장에서 객관적으로 말해줄 수 <br>
                    있어, 고객들이 더 신뢰하고 좋아해 줄 때 많은 <br>
                    보람을 느끼고 있습니다.
                </li>
                <li class="swiper-slide">
                    고객들께서 가까운 지인이나 전화로 보험을 <br>
                    가입하지만 정작 고민이 있거나 상담이 필요할 <br>
                    때 상담할 수 있는 곳이 없다 보니 보험클리닉에 <br>
                    오셔서 필요한 부분을 요청하거나 고민을 <br>
                    이야기하고, 고맙다고 하실 때 일에 대한 보람을 <br>
                    느끼고 있습니다.
                </li>
            </ul>
            <div class="swiper-pagination"></div>
        </div>
        <button type="button" class="prev">이전</button>
        <button type="button" class="next">다음</button>
    </section> -->
    <!-- //인터뷰 -->

    <!-- 영상자료 -->
    <!-- <section class="video">
        <div class="box_layer">
            <h3 class="title">일반 보험영업과 무엇이 다른가요?</h3>
            <div class="box">
                <button class="videoLayer" data-src="https://www.youtube.com/embed/EnOMcY6vAuE?playsinline=1" data-title="" data-type="youtube" type="button" title="영상재생">
                    <img class="thumb" src="https://img.youtube.com/vi/EnOMcY6vAuE/maxresdefault.jpg" alt="스크린샷">
                </button>                
                <p class="description"> 보험클리닉 매니저는 대한민국 전역에 위치한 <br> 매장(Shop)에 상주하며,  직접 보험의 필요성을 느껴 <br> 방문한 고객을 대상으로 보험상담 및 비교컨설팅을 <br> 제공합니다. </p>
            </div>
        </div>
        <div class="box_layer">
            <strong class="point">보험클리닉 유튜브</strong>
            <h3 class="title">상담매니저 일일 체험기</h3>
            <div class="box">
                <button class="videoLayer" data-src="https://www.youtube.com/embed/nsjj0fhNDIg?playsinline=1" data-title="" data-type="youtube" type="button" title="영상재생">
                    <img class="thumb" src="https://img.youtube.com/vi/nsjj0fhNDIg/maxresdefault.jpg" alt="스크린샷">
                </button>                
                <p class="description"> 고객 발굴에 지친 영업사원들이여~ <br> 쾌적한 근무환경, 이제는 찾지 말고 <br> 맞이 하자! </p>
                <div class="post_script">
                    <span> 늬들이 보험클리닉<br> 상담매니저를<br> 알아? </span>
                    <img :src="imgPath+'img_post_script.png'" alt="">
                </div>
            </div>
        </div>
    </section> -->
    <!-- //영상자료 -->

    <!-- 복리후생 -->
    <!-- <section class="welfare">
        <h3 class="title">복리후생</h3>
        <p class="description">
            보험클리닉 상담 매니저가 최적의 서비스 제공에 집중할
            수 있도록 쾌적하고 든든한 근무환경을 제공합니다.
        </p>
        <div class="clearfix icons_wrap icons_wrap--first">
            <div class="icons">
                <div class="icon">
                    <img :src="imgPath+'ico_welfare4.png'" class="icon__img" alt="고정급+인센티브 아이콘">
                    <p>인센티브 + α</p>
                </div>
                <div class="icon">
                    <img :src="imgPath+'ico_welfare11.png'" class="icon__img" alt="명절상여급 (연2회)">
                    <p>경조사비</p>
                </div>
                <div class="icon">
                    <img :src="imgPath+'ico_welfare10.png'" class="icon__img" alt="쾌적한 근무환경">
                    <p>쾌적한<br>근무환경</p>
                </div>
                <div class="icon">
                    <img :src="imgPath+'ico_welfare8.png'" class="icon__img" alt="연수 프로그램">
                    <p>연수 프로그램</p>
                </div>
                <div class="icon">
                    <img :src="imgPath+'ico_welfare14.png'" class="icon__img" alt="업무시간 탄력근무제">
                    <p>업무시간<br>탄력근무제</p>
                </div>
                <div class="icon">
                    <img :src="imgPath+'ico_welfare13.png'" class="icon__img" alt="점심시간 자율제">
                    <p>점심시간<br>자율제</p>
                </div>
            </div>
        </div>
    </section> -->
    <!-- //복리후생 -->
    
    <!-- 보험숍이란 -->
    <section class="explanation">
        <h3 class="title">보험숍(Shop)이란?</h3>
        <div class="box_layer">
            <h4 class="tit"><span>늘 같은 자리에 있는 우리 동네 보험전문가</span></h4>
            <div class="img"><img :src="imgPath+'img_explanation01.jpg?v=230327'" alt=""></div>
            <div class="txt">
                <p>
                    상담 매니저가 매장에 상주하며, <strong>스스로 보험의 <br>
                    필요성을 느껴 방문한 고객에게 서비스를 제공하는 <br>
                    비즈니스 모델</strong>입니다.
                </p>
                <p>
                    특히 한자리에 점포 형태로 영업을 하기에 지속적인 <br>
                    고객 관리가 가능하며, 국내 보험사와의 제휴를 <br>
                    통해 모든 보험에 대한 비교 분석을 제공할 수 있습니다.
                </p>
            </div>
        </div>
    </section>
    <!-- //보험숍이란 -->

    <!-- 전국보험숍 -->
    <section class="location">
        <h3 class="title">대한민국 보험의 새로운 역사</h3>
        <p class="description"> 번화가 상권을 중심으로 지속 확장 </p>
        <p class="img"> <img :src="imgPath+'img_location.png'" alt=""> </p>
        <span class="guide_text">※ 자세한 위치는 보험클리닉 홈페이지 ‘<a href="//m.bohumclinic.com/shop/find" target="_blank">보험샵찾기</a>’ 를 참고하세요</span>
        <div class="location_slide">
            <h4 class="tit">전국 어디서나 <strong>보험클리닉</strong></h4>
            <div class="swiper-container">
                <ul class="swiper-wrapper">
                    <li class="swiper-slide">
                        <img :src="imgPath+'img_location_list01.jpg'" alt="서울 이수점">
                        <span>서울 이수점</span>
                    </li>
                    <li class="swiper-slide">
                        <img :src="imgPath+'img_location_list02.jpg'" alt="서울 상일점">
                        <span>서울 상일점</span>
                    </li>
                    <li class="swiper-slide">
                        <img :src="imgPath+'img_location_list04.jpg'" alt="경기 안양점">
                        <span>경기 안양점</span>
                    </li>
                    <li class="swiper-slide">
                        <img :src="imgPath+'img_location_list05.jpg'" alt="인천 부평역점">
                        <span>인천 부평역점</span>
                    </li>
                    <li class="swiper-slide">
                        <img :src="imgPath+'img_location_list06.jpg'" alt="광주 상무점">
                        <span>광주 상무점</span>
                    </li>
                    <li class="swiper-slide">
                        <img :src="imgPath+'img_location_list07.jpg'" alt="경기 정발산점">
                        <span>경기 정발산점</span>
                    </li>
                    <li class="swiper-slide">
                        <img :src="imgPath+'img_location_list09.jpg'" alt="경기 판교점">
                        <span>경기 판교점</span>
                    </li>
                    <li class="swiper-slide">
                        <img :src="imgPath+'img_location_list10.png?v=220927'" alt="부산 해운대장산점">
                        <span>부산 해운대장산점</span>
                    </li>
                </ul>
            </div>
            <button type="button" class="prev">이전</button>
            <button type="button" class="next">다음</button>
        </div>
    </section>
    <!-- //전국보험숍 -->

    <!-- 3대서비스 -->
    <section class="service">
        <h3 class="title">보험클리닉 3대 서비스</h3>
        <p class="description">
            보험 고민 해결부터 사후관리까지!<br>
            상담 매니저의 1:1 맞춤 컨설팅
        </p>
        <div class="service_slide">
            <div class="swiper-container">
                <ul class="swiper-wrapper">
                    <li class="swiper-slide">
                        <span class="img"><img :src="imgPath+'img_service01.jpg'" alt=""></span>
                        <span class="txt">
                            <b>보상<br>청구</b>
                            사고가 나거나 수술 직후 몸을 움직이기<br>
                            힘든 상황에서 직접 보험금 청구가 힘든 고객을<br>
                            위한 보상청구 서비스를 운영하고 있습니다.
                        </span>
                    </li>
                    <li class="swiper-slide">
                        <span class="img"><img :src="imgPath+'img_service02.jpg'" alt=""></span>
                        <span class="txt">
                            <b>비교<br>추천</b>
                            하나의 보험사 상품만 제안할 수 있는 전속 대리점과<br>
                            달리 보험클리닉은 국내 존재하는  수많은<br>
                            보험상품의 비교와 설계가 가능합니다.
                        </span>
                    </li>
                    <li class="swiper-slide">
                        <span class="img"><img :src="imgPath+'img_service03.jpg'" alt=""></span>
                        <span class="txt">
                            <b>보험<br>진단</b>
                            자체적으로 개발한 지능형 보장분석 시스템인<br>
                            @ssist를 활용하여 보유 중인 보험을 진단하는<br>
                            맞춤형 리모델링을 제공하고 있습니다.
                        </span>
                    </li>
                </ul>
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </section>
    <!-- //3대서비스 -->

    <!-- 채용절차 -->
    <section class="hire_step">
        <h3 class="title">보험클리닉 채용절차</h3>
        <p class="description">서류전형부터 면접까지! 보험클리닉 입사에 대한 모든 것</p>
        <div class="step_cont">
            <ol class="step_list">
                <li class="step1">
                    <img :src="imgPath+'img_hire01.png'" alt="">
                    <em>서류전형</em>
                </li>
                <li class="step2">
                    <img :src="imgPath+'img_hire02.png'" alt="">
                    <em>실무진 면접</em>
                </li>
                <li class="step3">
                    <img :src="imgPath+'img_hire03.png'" alt="">
                    <em>임원면접</em>
                </li>
                <li class="step4">
                    <img :src="imgPath+'img_hire04.png'" alt="">
                    <em>최종합격</em>
                </li>
            </ol>
        </div>
        <div class="info_table">
             <!-- 모집부분 및 채용규모 -->
            <div class="table_css">
                <table summary="직군별 채용안내에 대한 내용을 확인 할 수 있습니다.">
                    <caption>모집부분 및 채용규모</caption>
                    <colgroup>
                        <col style="width:23%;">
                        <col style="width:*;">
                    </colgroup>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <th>모집분야</th>
                            <td>보험클리닉 상담매니저</td>
                        </tr>
                        <tr>
                            <th>주요업무</th>
                            <td>
                                <ul>
                                    <li>보험비교분석 시스템을 활용한 보험 분석 및 대안 제시</li>
                                    <li>고객응대 및 고객상담을 통한 영업</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>채용규모</th>
                            <td> 00명 </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 전형별 일정 -->
            <div class="table_css">
                <table>
                    <caption>전형별 일정</caption>
                    <colgroup>
                        <col style="width:23%;">
                        <col style="width:*;">
                    </colgroup>
                    <thead>
                    </thead>
                    <tbody>
                        <tr>
                            <th>서류전형</th>
                            <td>상시변경</td>
                        </tr>
                        <tr>
                            <th>면접일정</th>
                            <td>상시변경</td>
                        </tr>
                        <tr>
                            <th>인턴과정</th>
                            <td>상시변경</td>
                        </tr>
                    </tbody>
                </table>
                <!-- <div class="recruit_close"><img src="/img/recruit/recruitBohum/complete_mo.png" alt="채용마감"></div> -->
            </div>
            <!-- 지원방법 -->
            <div class="table_css">
                <table>
                    <caption>지원방법</caption>
                    <tbody>
                        <tr>
                            <td class="align-center">
                                <p class="mb-10"><strong>사람인 즉시지원 또는 자사이력서 작성 후 이메일 제출 → <a href="mailto:bohumclinic@peoplelife.co.kr">bohumclinic@peoplelife.co.kr</a></strong></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 고용형태 및 근무조건 -->
            <div class="table_css">
                <table>
                    <caption>고용형태 및 근무조건</caption>
                    <colgroup>
                        <col style="width:23%;">
                        <col style="width:*;">
                    </colgroup>
                    <thead> </thead>
                    <tbody>
                        <tr>
                            <th>고용형태</th>
                            <td>위촉직</td>
                        </tr>
                        <tr>
                            <th>근무시간</th>
                            <td>
                                <ul>
                                    <li>10:00 ~ 19:00시 근무</li>
                                    <li>일요일 및 공휴일 휴무</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>연봉</th>
                            <td>별도 문의</td>
                        </tr>
                        <tr>
                            <th>채용지역</th>
                            <td>전국</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- 지원자격 및 우대사항 -->
            <div class="table_css">
                <table>
                    <caption>지원자격 및 우대사항</caption>
                    <colgroup>
                        <col style="width:23%;">
                        <col style="width:*;">
                    </colgroup>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <th>지원자격</th>
                            <td>
                                <ul>
                                    <li>보험 영업직 경력 1년 이상</li>
                                    <li>초대졸 이상</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>우대사항</th>
                            <td>
                                <ul>
                                    <li>AFPK, CFP등 금융자격증 보유자</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>유의사항</th>
                            <td>
                                <ul>
                                    <li class="bullet_ex">[ 채용제한 ]</li>
                                    <li>신용등급 8등급 이하(점수 534점)</li>
                                    <li>보험회사 3년내 이직횟수 3회 이상자</li>
                                    <li>보증보험 가입 불가자</li>
                                    <li>지원서 내용이 사실과 다르거나 문서로 증빙이 불가능할 경우</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
             <!-- 지원방법 -->
            <div class="table_css">
                <table>
                    <caption>접수기간</caption>
                    <tbody>
                        <tr>
                            <td class="align-center">
                                <p class="txt_dday">상시채용</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- <div class="recruit_close"><img src="/img/recruit/recruitBohum/complete_mo.png" alt="채용마감"></div> -->
            </div>
        </div>
    </section>
    <!-- //채용절차 -->
    
    <!-- 입사지원신청 -->
    <section id="applyForm"  class="section" data-recruit-state="">  <!-- 종료시 data-recruit-state="deadline" -->
        <div class="inner">
            <h3 class="tit_section"><mark>보험클리닉<br>상담매니저 지원하기</mark></h3>
            <li class="row row_carrer_life row_css" data-check-pass="true" data-inbound-prop="lifeCareerYn" data-inbound-value="Y" data-check-comment="생보경력유무를 선택하세요">
                <label class="btn_carrer_life btnCarrerLife">
                    <input name="carrer_life" value="Y" type="radio" data-auto-focus="false" tabindex="-1" checked>
                    <em class="theme_border_color"><!--있음--></em>
                </label>
                <label class="btn_carrer_life btnCarrerLife">
                    <input name="carrer_life" value="N" type="radio" data-auto-focus="false" tabindex="-1">
                    <em class="theme_border_color"><!--없음--></em>
                </label>
            </li>
            <li class="row row_carrer_damage row_css" data-check-pass="true" data-inbound-prop="damageCareerYn" data-inbound-value="Y" data-check-comment="손보경력유무를 선택하세요">
                <label class="btn_carrer_damage btnCarrerDamage">
                    <input name="carrer_damage" value="Y" type="radio" data-auto-focus="false" tabindex="-1" checked>
                    <em class="theme_border_color"><!--있음--></em>
                </label>
                <label class="btn_carrer_damage btnCarrerDamage">
                    <input name="carrer_damage" value="N" type="radio" data-auto-focus="false" tabindex="-1">
                    <em class="theme_border_color"><!--없음--></em>
                </label>
            </li>
            <li class="row row_file row_css" data-check-pass="false" data-inbound-prop="fileAttachId" data-inbound-value="" data-check-comment="입사지원서를 업로드하세요">
                <button type="button"><img class="ico" src="" data-images-path="/image/svg/clip01.svg"></button>
                <span class="file_name"></span>
                <input type="file" class="hidden">
            </li>
            <div class="loadForm"></div>
            <div class="box_info">
                <p class="tit">보험클리닉 상담매니저 <strong>입사지원서 다운받기</strong></p>
                <p class="desc">입사지원서를 작성하신 후 제출해주시기 바랍니다.<br><span>※ 다른 양식의 입사지원서로도 지원 가능합니다.<br>(잡코리아, 사람인 이력서도 제출 가능)</span></p>
                <a class="link_download" href="" data-images-path="/data/doc/bohumclinic_resume.docx" download><span>입사지원서 다운로드</span><img class="ico" src="" data-images-path="/image/svg/download02.svg"></a>
            </div>
        </div>
    </section>
    <!-- //입사지원신청 -->

    <!-- FAQ -->
    <section class="faq">
        <h3 class="title">FAQ</h3>
        <dl class="faq_list">
            <dt>주요 업무가 무엇인가요?</dt>
            <dd>
                피플라이프가 자체 개발한 지능형 보장분석 시스템을 통해<br>
                <strong>기존 보험의 과다·부족 여부를 진단하는 컨설팅</strong>을 진행하며, <br>
                <strong>보험금 청구 대행, 숨은 보험금 찾기 등 기타 서비스를<br>
                안내</strong>합니다.
            </dd>
            <dt>보험을 판매해야 하나요?</dt>
            <dd>
                보험대리점의 주 수입원은 보험계약이며, 보험클리닉 또한 <br>
                보험 판매를 통해 운영됩니다.
            </dd>
            <dt>입사 후 과정은 어떻게 진행되나요?</dt>
            <dd>
                신입 교육 및 현장 OJT를 거쳐 매니저의 거주지 등을<br> 고려하여 배치합니다.
            </dd>
        </dl>
    </section>
    <!-- //FAQ -->

    <!-- 문의사항 -->
    <section class="question">
        <div class="box">
            <h3>채용문의</h3>
            <a href="tel:1661-8422">1661-8422</a>
            <em>bohumclinic@peoplelife.co.kr</em>
            <p>
                <span>평일 09:00~18:30</span>
                <span>근무시간 외 E-mail 문의</span>
            </p>
        </div>
        <span class="txt1">새로운 Role Model의 주인공이 되고 싶다면?</span>
        <em class="txt2"> 지금 바로. 잡으세요!<br> 당신이 그 기회의 주인공 입니다. </em>
        <a class="telbox" href="tel:02-2251-6862">채용문의 02-2251-6862</a>
        <em class="txt3">※ 본 화면은 보험설계사를 모집하는 것으로 정규 또는 비정규 직원채용과 무관합니다.</em>
    </section>
    <!-- //문의사항 -->

    <button class="floting anchor" data-target="#applyForm" type="button" ><img :src="imgPath+'img_floting.png'" alt="입사지원"></button>
</main>
<!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/recruit/recruitBohum/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/recruit/recruitBohum.scss';
</style>