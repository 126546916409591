<template>
<!-- main -->
<main id="container" class="newScrollAction" data-name="bohum">
    <h1 class="screen_out">보험클리닉</h1>

    <!-- 메인비주얼 -->
    <section class="visual">
        <div class="txt_box">
            <em><img :src="imgPath+'bohum_logo.png'" alt="보험클리닉"></em>
            <p>고객이 직접 방문하는</p>
            <strong><span>보험클리닉</span> 오프라인 보험샵</strong>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- 주요정보 -->
    <section class="information section">
        <h2 class="title"><img :src="imgPath+'bohum_title.png'" alt="clinic"></h2>
        <div class="exp">
            <div>
                <p class="img"><img :src="imgPath+'infomation_thumb.jpg?v=2211241'" alt=""></p>
                <p class="tit">고객님과 가장 가까운 곳에서<br> 보험을 바로. 잡아드립니다. </p>
                <p class="txt">보험도 마트나 백화점에서 쇼핑하듯이 상황과 니즈에<br>맞게 보험상품을 능동적으로 쇼핑하는 시대입니다.<br> 고객의 편의성을 최우선적으로 고려하는 보험클리닉은<br>APP·인터넷(웹)·모바일(전화)를 활용하여 방문<br>예약할 수 있습니다.<br> 전국 주요 도시의 보험샵에서 직접 경험해 보세요. </p>
            </div>
            <a href="//m.bohumclinic.com/shop" target="_blank" class="arrow_btn">보험클리닉 SHOP 바로가기</a>
        </div>
    </section>
    <!-- //주요정보 -->

    <!-- 차별화 -->
    <section id="people" class="section">
        <div class="inner">
            <h3 class="tit_section">보험클리닉 3대 차별화 서비스</h3>
            <ul class="list_people">
                <li class="item_people isAppear80">
                    <p class="tit">보험진단 서비스</p>
                    <p class="desc">자체 개발한 보험클리닉App으로<br> 보유보험 적정성 진단</p>
                </li>
                <li class="item_people isAppear80">
                    <p class="tit">비교추천 서비스</p>
                    <p class="desc">국내 주요 보험사와 제휴하여 다수 상품 中<br> 고객이 직접선택</p>
                </li>
                <li class="item_people isAppear80">
                    <p class="tit">보험금 청구 대행 서비스</p>
                    <p class="desc">가입한 보험사 상관없이<br> 무료 보험금 청구 대행 서비스</p>
                </li>
            </ul>
        </div>
    </section>
    <!-- //차별화 -->

    <!-- 숫자로 보는 보험클리닉 -->
    <section id="viewed" class="section">
        <div class="inner">
            <h3 class="tit_section">숫자로 보는 보험클리닉</h3>
            <div class="swiper-container swiper-thumb">
                <ul class="swiper-wrapper">
                    <li class="swiper-slide">
                        <img class="thumb" :src="imgPath+'viewed_thumb1.jpg?v=2211241'" alt="">
                    </li>
                    <li class="swiper-slide">
                        <img class="thumb" :src="imgPath+'viewed_thumb2.jpg'" alt="">
                    </li>
                    <li class="swiper-slide">
                        <img class="thumb" :src="imgPath+'viewed_thumb3.jpg'" alt="">
                    </li>
                </ul>
            </div>
            <div class="swiper-container swiper-bottom">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <p class="desc">2019년 4월 Grand Open 이후</p>
                        <p class="tit">누적 방문자 수</p>
                        <p class="count"><span class="num">0</span><span class="unit">명</span></p>
                        <p class="source"><span class="date"></span>(자사 방문상담 데이터 기준)</p>
                    </div>
                    <div class="swiper-slide">
                        <p class="desc">2019년 4월 Grand Open 이후</p>
                        <p class="tit">보험클리닉 SHOP 지점 수</p>
                        <p class="count"><span class="num">0</span><span class="unit">개</span></p>
                        <p class="source"><span class="date"></span>(자사 보험 SHOP점포 데이터 기준)</p>
                    </div>
                    <div class="swiper-slide">
                        <p class="desc">보험클리닉 SHOP 홈페이지 </p>
                        <p class="tit">상담 예약 수 </p>
                        <p class="count"><span class="num">0</span><span class="unit">건</span></p>
                        <p class="source"><span class="date"></span>(자사 상담예약 데이터 기준)</p>
                    </div>
                </div>
                <div class="swiper-nav">
                    <!-- Add Navigation -->
                    <button class="swiper-button-prev swiper-button-black" type="button"></button>
                    <button class="swiper-button-next swiper-button-black" type="button"></button>
                </div>
            </div>
            <div class="pagination">
                <div class="count">
                    <span class="num"></span> / <span class="total"></span>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </section>
    <!-- //숫자로 보는 보험클리닉 -->

    <!-- TV 광고 -->
    <section class="tvadvertising section">
        <h3 class="title">보험클리닉 TV CF</h3>
        <p class="desc">어려운 보험.. 알아듣게 설명해 줄 사람 없나?<br>만나! 보험클리닉</p>
        <div class="swiper-container">
            <ul class="swiper-wrapper">
                <li class="swiper-slide">
                    <button data-src="/file/video/2024_tvcf2_kakao.mp4" data-title="" data-type="localhost" type="button" title="영상재생">
                        <img class="thumb" src="/file/video/2024_tvcf2_kakao.jpg" alt="스크린샷">
                    </button>
                    <p class="tit">&lt;카톡&gt;편</p>
                </li>
                <li class="swiper-slide">
                    <button data-src="/file/video/2024_tvcf2_search.mp4" data-title="" data-type="localhost" type="button" title="영상재생">
                        <img class="thumb" src="/file/video/2024_tvcf2_search.jpg" alt="스크린샷">
                    </button>
                    <p class="tit">&lt;검색&gt;편</p>
                </li>
                <li class="swiper-slide">
                    <button data-src="/file/video/2024_tvcf2_youtube.mp4" data-title="" data-type="localhost" type="button" title="영상재생">
                        <img class="thumb" src="/file/video/2024_tvcf2_youtube.jpg" alt="스크린샷">
                    </button>
                    <p class="tit">&lt;유튜브&gt;편</p>
                </li>
                <li class="swiper-slide">
                    <button data-src="/video/tvcf/bohumclinic/2023_season1_1.mp4" data-title="" data-type="" type="button" title="영상재생">
                        <img class="thumb" src="/file/video/2023_season1_1.jpg" alt="스크린샷">
                    </button>
                    <p class="tit">&lt;보험고민&gt;편</p>
                </li>
                <li class="swiper-slide">
                    <button data-src="/video/tvcf/bohumclinic/2023_season2_1.mp4" data-title="" data-type="" type="button" title="영상재생">
                        <img class="thumb" src="/file/video/2023_season2_1.jpg" alt="스크린샷">
                    </button>
                    <p class="tit">&lt;실손&gt;편</p>
                </li>
                <li class="swiper-slide">
                    <button data-src="/video/tvcf/bohumclinic/2023_season3_1.mp4" data-title="" data-type="" type="button" title="영상재생">
                        <img class="thumb" src="/file/video/2023_season3_1.jpg" alt="스크린샷">
                    </button>
                    <p class="tit">&lt;일반&gt;편</p>
                </li>
                <li class="swiper-slide">
                    <button data-src="/video/tvcf/bohumclinic/2020_season2_1.mp4" data-title="" data-type="" type="button" title="영상재생">
                        <img class="thumb" src="" data-images-path="/video/tvcf/bohumclinic/2020_season2_1.jpg" alt="스크린샷">
                    </button>
                    <p class="tit">&lt;나이스&gt;편</p>
                </li>
                <li class="swiper-slide">
                    <button data-src="/video/tvcf/bohumclinic/2020_season2_2.mp4" data-title="" data-type="" type="button" title="영상재생">
                        <img class="thumb" src="" data-images-path="/video/tvcf/bohumclinic/2020_season2_2.jpg" alt="스크린샷">
                    </button>
                    <p class="tit">&lt;자꾸만 보고 싶네&gt;편</p>
                </li>
                <li class="swiper-slide">
                    <button data-src="/video/tvcf/bohumclinic/2019_season2_1.mp4" data-title="" data-type="" type="button" title="영상재생">
                        <img class="thumb" src="" data-images-path="/video/tvcf/bohumclinic/2019_season2_1.jpg" alt="스크린샷">
                    </button>
                    <p class="tit">&lt;어쩌다 마주친&gt;편</p>
                </li>
                <li class="swiper-slide">
                    <button data-src="/video/tvcf/bohumclinic/2019_season2_2.mp4" data-title="" data-type="" type="button" title="영상재생">
                        <img class="thumb" src="" data-images-path="/video/tvcf/bohumclinic/2019_season2_2.jpg" alt="스크린샷">
                    </button>
                    <p class="tit">&lt;여기서 내려요&gt;편</p>
                </li>
                <li class="swiper-slide">
                    <button data-src="/video/tvcf/bohumclinic/2019_season1_2.mp4" data-title="" data-type="" type="button" title="영상재생">
                        <img class="thumb" src="" data-images-path="/video/tvcf/bohumclinic/2019_season1_2.jpg" alt="스크린샷">
                    </button>
                    <p class="tit">&lt;보험료&gt;편</p>
                </li>
                <li class="swiper-slide">
                    <button data-src="/video/tvcf/bohumclinic/2019_season1_1.mp4" data-title="&lt;보장&gt;편" data-type="" type="button" title="영상재생">
                        <img class="thumb" src="" data-images-path="/video/tvcf/bohumclinic/2019_season1_1.jpg" alt="스크린샷">
                    </button>
                    <p class="tit">&lt;보장&gt;편</p>
                </li>
            </ul>
        </div>
        <div class="pagination">
            <div class="count">
                <span class="num"></span> / <span class="total"></span>
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </section>
    <!-- //TV 광고 -->

    <!-- 라이프 케어 -->
    <section id="lifecare" class="section">
        <h3 class="tit_section">생애 전반의 라이프 케어 컨설팅을<br>보험클리닉에서 한번에!</h3>
        <p class="desc_section">보험클리닉에 방문하시면<br> 다양한 보험상담 솔루션 제공이 가능합니다.</p>
        <div class="swiper-container swiper-tab">
            <div class="swiper-wrapper">                
                <div class="swiper-slide"><button type="button">건강</button></div>
                <div class="swiper-slide"><button type="button">어린이</button></div>
                <div class="swiper-slide"><button type="button">치매&#183;간병</button></div>
                <div class="swiper-slide"><button type="button">일상생활</button></div>
                <div class="swiper-slide"><button type="button">자녀자금</button></div>
                <div class="swiper-slide"><button type="button">노후자금</button></div>
                <div class="swiper-slide"><button type="button">증여&#183;상속</button></div>
                <div class="swiper-slide"><button type="button">사망</button></div>
            </div>
        </div>
        <div class="swiper-container swiper-info">
            <div class="swiper-wrapper">                
                <div class="swiper-slide">
                    <img class="thumb" :src="imgPath+'lifecare_thumb2.jpg'" alt="">
                    <p class="tit">건강 보장 솔루션 </p>
                    <p class="desc">질병과 상해로 지출될 병원비를 대비하여 암, 뇌혈관, 심혈관, 입원, 수술 그리고 실손 보장이 필요 </p>
                </div>
                <div class="swiper-slide">
                    <img class="thumb" :src="imgPath+'lifecare_thumb3.jpg'" alt="">
                    <p class="tit">어린이 보장 솔루션</p>
                    <p class="desc">신생아보험, 어린이보험 등 어릴 때 부터 미리 100세 시대를 대비한 보험을 준비 </p>
                </div>
                <div class="swiper-slide">
                    <img class="thumb" :src="imgPath+'lifecare_thumb4.jpg'" alt="">
                    <p class="tit">치매·간병 보장 솔루션</p>
                    <p class="desc">장해로 인한 소득 중단과 어느 가정에서나 일어날 수 있는 치매를 대비할 수 있는 자금 </p>
                </div>
                <div class="swiper-slide">
                    <img class="thumb" :src="imgPath+'lifecare_thumb5.jpg'" alt="">
                    <p class="tit">일상생활 보장 솔루션</p>
                    <p class="desc">운전자 보험, 치아보험, 화재보험 등 일상생활 중 필요한 보장을 준비 </p>
                </div>
                <div class="swiper-slide">
                    <img class="thumb" :src="imgPath+'lifecare_thumb6.jpg'" alt="">
                    <p class="tit">자녀자금 보장 솔루션 </p>
                    <p class="desc">자녀의 대학 및 대학원 등록비, 유학자금, 그리고 결혼자금 준비 </p>
                </div>
                <div class="swiper-slide">
                    <img class="thumb" :src="imgPath+'lifecare_thumb7.jpg'" alt="">
                    <p class="tit">노후자금 보장 솔루션 </p>
                    <p class="desc">기대수명 100세 시대에 보다 풍요롭고 여유로운 노후를 위한 자금 준비 </p>
                </div>
                <div class="swiper-slide">
                    <img class="thumb" :src="imgPath+'lifecare_thumb8.jpg'" alt="">
                    <p class="tit">증여·상속 보장 솔루션</p>
                    <p class="desc">계획적인 증여와 상속은 바로 해결해야 되는 문제 </p>
                </div>
                <div class="swiper-slide">
                    <img class="thumb" :src="imgPath+'lifecare_thumb1.jpg'" alt="">
                    <p class="tit">사망 보장 솔루션</p>
                    <p class="desc">주 수입원이나 남편이 조기 사망할 경우 장례비, 사망 정리자금과 남겨진 가족에게 필요한 생활자금, 주택자금, 자녀 양육자금 등을 준비</p>
                </div>
            </div>
        </div>
        <div class="box_book isAppear80">
            <img class="book" :src="imgPath+'lifecare_book.png?v=230327'" alt="">
            <p class="tit">보험클리닉만의 '보클 처방전'으로 믿을 수 있는 <strong>보험클리닉 상담 매니저가 보험을 무료로 진단</strong>해드립니다. </p>
        </div>
    </section>
    <!-- //라이프 케어 -->

    <!-- 컨설팅 프로세스 -->
    <section id="process">
        <h3 class="title">보험클리닉 상담 프로세스</h3>
        <ul class="list_process">
            <li class="item_process isAppear80">
                <i class="ico_bullet"></i>
                <p class="tit">STEP 1.<em>보험문진</em></p>
                <div class="box_desc">
                    <img :src="imgPath+'process_thumb1.jpg'" alt="">
                    <dl class="list_desc">
                        <dt>상담신청 및 안내</dt>
                        <dt>기초 문진</dt>
                        <dt>어플리케이션 설치 및<br>보험 파악</dt>                        
                    </dl>
                </div>
            </li>
            <li class="item_process isAppear80">
                <i class="ico_bullet"></i>
                <p class="tit">STEP 2.<em>보험진단</em></p>
                <div class="box_desc">
                    <img :src="imgPath+'process_thumb2.jpg'" alt="">
                    <dl class="list_desc">
                        <dt>보험증권 파악 및 보장<br> 분석</dt>
                        <dt>보험 목적별 협의</dt>
                        <dt>협의안 도출</dt>                        
                    </dl>
                </div>
            </li>
            <li class="item_process isAppear80">
                <i class="ico_bullet"></i>
                <p class="tit">STEP 3.<em>보험처방</em></p>
                <div class="box_desc">
                    <img :src="imgPath+'process_thumb3.jpg?v=221124'" alt="">
                    <dl class="list_desc">
                        <dt>솔루션 제시</dt>
                        <dt>솔루션 실행</dt>
                        <dt>금융상품 비교추천</dt>                        
                    </dl>
                </div>
            </li>
            <li class="item_process isAppear80">
                <i class="ico_bullet"></i>
                <p class="tit">STEP 4.<em>보험관리</em></p>
                <div class="box_desc">
                    <img :src="imgPath+'process_thumb4.jpg'" alt="">
                    <dl class="list_desc">
                        <dt>보상청구 서비스</dt>
                        <dt>일상적인 서비스 및<br>이벤트 안내</dt>
                        <dt>고객이 필요 시 언제든</dt>                        
                    </dl>
                </div>
            </li>
        </ul>
    </section>
    <!-- //컨설팅 프로세스 -->

    <!-- 방문 및 상담신청 -->
    <section id="visit" class="section">
        <div class="inner">
            <h3 class="tit_section">보험클리닉을 만나는<br>쉽고 빠른 방법</h3>
            <ul class="list_visit">
                <li class="item_visit">
                    <i class="ico"></i>
                    <p class="tit">홈페이지 상담신청</p>
                    <p class="desc">보험클리닉 홈페이지에서<br> 가까운 보험샵에 신청하세요!</p>
                    <a class="link_more" href="//m.bohumclinic.com/shop/helpdesk/quickconsult/" target="_blank"><span>방문신청 하기</span><img src="" data-images-path="/image/svg/arr_right01.svg"></a>
                </li>
                <li class="item_visit">
                    <i class="ico"></i>
                    <p class="tit">직접방문 </p>
                    <p class="desc">가까운 보험샵을 찾아<br> 바로 방문하세요.</p>
                    <a class="link_more" href="//m.bohumclinic.com/shop/find" target="_blank"><span>보험샵 찾기</span><img src="" data-images-path="/image/svg/arr_right01.svg"></a>
                </li>
                <li class="item_visit">
                    <i class="ico"></i>
                    <p class="tit">전화 상담신청</p>
                    <p class="desc">1661-5085에서<br> 상담신청을 도와드려요.</p>
                    <a class="link_more" href ="tel:1661-5085"><span>상담신청</span><img src="" data-images-path="/image/svg/arr_right01.svg"></a>
                </li>
                <li class="item_visit mb-100">
                    <i class="ico"></i>
                    <p class="tit">보험클리닉 APP </p>
                    <p class="desc">손 안에서 보험클리닉<br> 서비스를 경험해보세요.</p>
                    <a class="app" href="//play.google.com/store/apps/details?id=kr.co.peoplelife.panc" target="blank"><img :src="imgPath+'visit_google.jpg'" alt=""></a>
                    <a class="app" href="//apps.apple.com/kr/app/%EB%B3%B4%ED%97%98%ED%81%B4%EB%A6%AC%EB%8B%89/id1373790260" target="blank"><img :src="imgPath+'visit_apple.jpg'" alt=""></a>
                </li>
            </ul>
        </div>
    </section>    
    <!-- //방문 및 상담신청 -->

    <!-- 안내사항 -->
    <section id="about" class="section">
        <div class="inner">
            <p class="desc_section">새로운 Role Model의 주인공이 되고 싶다면? </p>
            <h3 class="tit_section">지금 바로. 잡으세요!<br><strong>당신이 그 기회의 주인공 입니다.</strong></h3>
            <a class="link_about" href="/recruit/RecruitBohum"><span>보험클리닉 인재채용 바로가기</span><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></a>
            <img class="model" :src="imgPath+'about_model.png'" alt="모델">
        </div>
    </section>
    <!-- //안내사항 -->        
</main>
<!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/sales/otc/'
        }
    }
}
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/sales/otc.scss';
</style>
