<template>
<!-- main -->
<main id="container" class="newScrollAction" data-name="history">
    <h1 class="screen_out">연혁</h1>

    <!-- 메인비주얼 -->
    <section id="visual" class="section">
        <div class="swiper-container visual-slide">
            <ul class="swiper-wrapper">
                <li class="swiper-slide slide1">
                    <p class="txt1"> 한 눈에 보는 <strong>피플라이프<br>발자취</strong> </p>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide2">
                    <div class="center">
                        <strong class="year">2003</strong>
                        <p class="txt">피플라이프 설립</p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide3">
                    <div class="center">
                        <strong class="year">2006</strong>
                        <p class="txt"> GA업계에서<br> 경영효율화컨설팅 시행 </p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide4">
                    <div class="center">
                        <strong class="year">2010</strong>
                        <p class="txt">전국 영업 네트워크<br>구축완료</p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide5">
                    <div class="center">
                        <strong class="year">2012</strong>
                        <p class="txt"> 매출 660억,<br> 법인고객수 9,000사 돌파 </p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide6">
                    <div class="center">
                        <strong class="year">2013</strong>
                        <p class="txt"> 공중파 광고(조인성) </p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide7">
                    <div class="center">
                        <strong class="year">2014</strong>
                        <p class="txt"> 매경경영지원본부 론칭,<br> 매경미디어그룹과 업무제휴 </p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide8">
                    <div class="center">
                        <strong class="year">2015</strong>
                        <p class="txt"> 중소기업 CEO 대상 <br> 골프 클래식 개최</p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide9">
                    <div class="center">
                        <strong class="year">2016</strong>
                        <p class="txt"> 매출 1,200억 초과<br> 개인영업 7대<br>영업지원시스템 구축 </p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide10">
                    <div class="center">
                        <strong class="year">2017</strong>
                        <p class="txt">
                            법인고객수 40,000사 돌파<br>
                            매출 1,400억
                        </p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide11">
                    <div class="center">
                        <strong class="year">2018</strong>
                        <p class="txt">
                            매출 20,000억원 돌파<br>
                            내방형 점포 ‘보험클리닉’ 런칭
                        </p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide12">
                    <div class="center">
                        <strong class="year">2019</strong>
                        <p class="txt">
                            코스톤아시아(PEF)<br>
                            610억 투자유치
                        </p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide13">
                    <div class="center">
                        <strong class="year">2020</strong>
                        <p class="txt">
                            정규직 EFA 도입<br>
                            내방형 점포 100호점 돌파
                        </p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide14">
                    <div class="center">
                        <strong class="year">2021</strong>
                        <p class="txt">
                            소비자 보호 ‘완전판매‘ 개최<br>
                            완전판매문화 ‘클린피플’ 캠페인 전개
                        </p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide15">
                    <div class="center">
                        <strong class="year">2022</strong>
                        <p class="txt">
                            22년 피플라이프 본사 이전<br>
                            (서초구 방배천로 91, 17층)
                        </p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide16">
                    <div class="center">
                        <strong class="year">2023</strong>
                        <p class="txt">
                            23년 한화생명금융서비스(주)<br>
                            자회사로 편입
                        </p>
                    </div>
                    <span class="back-i"></span>
                </li>
                <li class="swiper-slide slide17">
                    <div class="center">
                        <strong class="year">2024</strong>
                        <p class="txt">
                            병원에서 만나는<br>
                            'M보험클리닉' 신규 론칭
                        </p>
                    </div>
                    <span class="back-i"></span>
                </li>
            </ul>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <section class="history_list">
        <div class="year_list top">
            <ul>
                <li data-id="id2023" class="on"><button type="button">현재-2023</button></li>
                <li data-id="id2022"><button type="button">2022~2020</button></li>
                <li data-id="id2019"><button type="button">2019~2018</button></li>
                <li data-id="id2017"><button type="button">2017~2013</button></li>
                <li data-id="id2012"><button type="button">2012~2003</button></li>
            </ul>
        </div>

        <div id="id2023" class="history_contents">
            <div class="year_visual">
                <span class="year"><img src="/img/intro/history/txt_num2023.png" alt="Present-2023"></span>
                <span class="text">
                    한화생명금융서비스의 자회사 편입과 함께<br>
                    새로운 사업 전략 및 미래 성장을 위한 계획을 수립 중입니다.
                </span>
                <em class="img"><img src="/img/intro/history/img_id2023.jpg" alt=""></em>
            </div>
            <div class="step_contens">
                <div class="step">
                    <strong>2024.08</strong>
                    <ul>
                        <li>보험클리닉 수원망포점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2024.07</strong>
                    <ul>
                        <li>세무사 특화 조직 'CEO클리닉 T앨리' 출범</li>
                        <li>2024년 우수인증설계사 302명 배출</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2024.06</strong>
                    <ul>
                        <li>2024 프리미엄 브랜드 대상 수상 (주최:주간동아)</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2024.05</strong>
                    <ul>
                        <li>2024 피플라이프 연도대상 진행</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2024.04</strong>
                    <ul>
                        <li>보험클리닉 신규 광고 3편 론칭(유튜브, 검색, 카톡)</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2024.03</strong>
                    <ul>
                        <li>23년 하반기 결산 '클린피플' 선정</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2024.02</strong>
                    <ul>
                        <li>병원에서 만나는 'M보험클리닉' 신규 론칭</li>
                        <li>VIP고객을 위한 전문가 그룹 '프리미어센터' 오픈</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2023.10</strong>
                    <ul>
                        <li>2023 만나 Show! 대규모 행사 진행</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2023.05</strong>
                    <ul>
                        <li>보험클리닉 신규 '현빈 광고' 캠페인 론칭</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2023.02</strong>
                    <ul>
                        <li>헬스케어 플랫폼 기업 ‘이브이케어’와 MOU 체결</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2023.01</strong>
                    <ul>
                        <li>한화생명금융서비스(주)의 자회사로 편입</li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="id2022" class="history_contents">
            <div class="year_visual">
                <span class="year"><img src="/img/intro/history/txt_num2022-20.png" alt="2022-2020"></span>
                <span class="text">
                    내방형 점포 확대 및 다채널 전략 등 전향적 행보로,<br>
                    ‘Originality’를 갖춘 금융업계 선도 기업으로 도약하고 있다.
                </span>
                <em class="img"><img src="/img/intro/history/img_id2020.jpg" alt=""></em>
                <span class="trophy"><img src="/img/intro/history/img_trophy.png" alt=""></span>
            </div>
            <div class="step_contens">
                <div class="step">
                    <strong>2022.08</strong>
                    <ul>
                        <li>피플라이프 본사 이전(서초구 방배천로 91, 17층)</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2021.12</strong>
                    <ul>
                        <li>옵티메드와 병원경영컨설팅 서비스<br>업무협력협약(MOU) 체결</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2021.11</strong>
                    <ul>
                        <li>한국 M&amp;A 거래소와 협력 MOU 체결</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2021.09</strong>
                    <ul>
                        <li>보맵과 업무협약 체결</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2021.08</strong>
                    <ul>
                        <li>클린피플(모범인증FA) 캠페인 전개</li>
                        <li>보험클리닉 해운대장산점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2021.07</strong>
                    <ul>
                        <li>보험클리닉 연신내점 개점</li>
                        <li>보험클리닉 천호점 개점</li>
                        <li>보험클리닉 울산동구점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2021.05</strong>
                    <ul>
                        <li>헬스맥스와 전략적 업무협력 협약 체결</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2021.04</strong>
                    <ul>
                        <li>소비자 보호'완전판매' 캠페인 개최</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2021.03</strong>
                    <ul>
                        <li>2021 대한민국 소비자 선호도 1위 수상 <br>(주최: 주간동아)</li>
                        <li>보험클리닉 반월당센터점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2021.02</strong>
                    <ul>
                        <li>OBS 우리집 머니스토리 방영</li>
                        <li>OBS 보험탄탄 인생탄탄 방영</li>
                        <li>보험클리닉 양산점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2021.01</strong>
                    <ul>
                        <li>희망2021 나눔캠페인 성금 기부 참여(KBS 보도)</li>
                        <li>보험클리닉 흥덕점 개점</li>
                        <li>보험클리닉 동춘점 개점</li>
                        <li>보험클리닉 홍제점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2020.12</strong>
                    <ul>
                        <li>보험클리닉 봉선점 개점</li>
                        <li>보험클리닉 청주점 개점</li>
                        <li>보험클리닉 화성동탄점 개점</li>
                        <li>보험클리닉 계양점 개점</li>
                        <li>보험클리닉 월드컵점 개점</li>
                        <li>보험클리닉 대전가오점 개점</li>
                        <li>보험클리닉 합정점 개점</li>
                        <li>보험클리닉 왕십리점 개점</li>
                        <li>보험클리닉 수완점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong>2020.11</strong>
                    <ul>
                        <li>보험클리닉 위례점 개점</li>
                        <li>보험클리닉 파주운정중앙점 개점</li>
                        <li>보험클리닉 관악점 개점</li>
                        <li>보험클리닉 검단점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2020.10</strong>
                    <ul>
                        <li>보험클리닉 칠곡점 개점</li>
                        <li>보험클리닉 아산점 개점</li>
                        <li>보험클리닉 동래중앙점 개점</li>
                        <li>보험클리닉 사상중앙점 개점</li>
                        <li>보험클리닉 강변점 개점</li>
                        <li>보험클리닉 보라점 개점</li>
                        <li>보험클리닉 마포점 개점</li>
                        <li>보험클리닉 의정부민락점 개점</li>
                        <li>보험클리닉 울산남구점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2020.09</strong>
                    <ul>
                        <li>보험클리닉 세종점 개점</li>
                        <li>보험클리닉 가재울뉴타운점 개점</li>
                        <li>보험클리닉 구미점 개점</li>
                        <li>보험클리닉 반야월점 개점</li>
                        <li>보험클리닉 은평점 개점</li>
                        <li>보험클리닉 대전터미널점 개점</li>
                        <li>보험클리닉 연제점 개점</li>
                        <li>보험클리닉 광명소하점 개점</li>
                        <li>보험클리닉 파주운정점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2020.08</strong>
                    <ul>
                        <li>보험클리닉 서수원점 개점</li>
                        <li>보험클리닉 부산본점 개점</li>
                        <li>보험클리닉 죽전점 개점</li>
                        <li>보험클리닉 수지점 개점</li>
                        <li>보험클리닉 신월점 개점</li>
                        <li>보험클리닉 대구점 개점</li>
                        <li>보험클리닉 가든파이브 개점</li>
                        <li>보험클리닉 다산점 개점</li>
                        <li>보험클리닉 부천소사점 개점</li>
                        <li>보험클리닉 성수점 개점</li>
                        <li>보험클리닉 금정점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2020.07</strong>
                    <ul>
                        <li>보험클리닉 롯데월드점 개점</li>
                        <li>보험클리닉 야탑점 개점</li>
                        <li>보험클리닉 가양중앙점 개점</li>
                        <li>보험클리닉 수원중앙점 개점</li>
                        <li>보험클리닉 광교점 개점</li>
                        <li>보험클리닉 김포한강점 개점</li>
                        <li>보험클리닉 잠실중앙점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2020.06</strong>
                    <ul>
                        <li>CEO클리닉 모바일 웹서비스 오픈</li>
                        <li>보험클리닉 김포점 개점</li>
                        <li>보험클리닉 천안신방점 개점</li>
                        <li>보험클리닉 김포공항점 개점</li>
                        <li>보험클리닉 광복점 개점</li>
                        <li>롯데마트와의 전략적 업무제휴협약(MOU)체결</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2020.05</strong>
                    <ul>
                        <li>보험클리닉 서울역점 개점</li>
                        <li>보험클리닉 중동점 개점</li>
                        <li>보험클리닉 타임스퀘어점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2020.04</strong>
                    <ul>
                        <li>보험대리점 브랜드평판 1위 선정(20년 4월) <br>(주최 : 한국기업평판연구소)</li>
                        <li>보험클리닉 양재점 개점</li>
                        <li>보험클리닉 일산중앙점 개점</li>
                        <li>보험클리닉 신내점 개점</li>
                        <li>보험클리닉 영통점 개점</li>
                        <li>보험클리닉 반여점 개점</li>
                        <li>보험클리닉 북수원점 개점</li>
                        <li>보험클리닉 스타필드하남점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2020.03</strong>
                    <ul>
                        <li>보험대리점 브랜드평판 1위 선정(20년 3월) <br>(주최 : 한국기업평판연구소)</li>
                        <li>BNP파리바 카디프생명 업무제휴</li>
                        <li>보험클리닉 김해중앙점 개점</li>
                        <li>보험클리닉 송천점 개점</li>
                        <li>보험클리닉 인천숭의점 개점</li>
                        <li>보험클리닉 화정점 개점</li>
                        <li>보험클리닉 동대전점 개점</li>
                        <li>보험클리닉 노은점 개점</li>
                        <li>보험클리닉 상일점 개점</li>
                        <li>보험클리닉 연수점 개점</li>
                        <li>보험클리닉 동청주점 개점</li>
                        <li>보험클리닉 일산점 개점</li>
                        <li>보험클리닉 정읍점 개점</li>
                        <li>보험클리닉 동래점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2020.02</strong>
                    <ul>
                        <li>고객이 신뢰하는 브랜드대상 수상 <br>(주최 : 한경비즈니스)</li>
                        <li>국내 독립 보험대리점(GA) 브랜드평판 1위 <br>(주최 : 한국기업평판연구소)</li>
                        <li>보험클리닉 서대전점 개점</li>
                        <li>보험클리닉 송파점 개점</li>
                        <li>보험클리닉 동대문점 개점</li>
                        <li>보험클리닉 신정네거리점 개점</li>
                        <li>보험클리닉 신당점 개점</li>
                        <li>보험클리닉 노원중앙점 개점</li>
                        <li>보험클리닉 선부점 개점</li>
                        <li>보험클리닉 미사점 개점</li>
                        <li>보험클리닉 상록점 개점</li>
                        <li>보험클리닉 당진점 개점</li>
                        <li>보험클리닉 한성대입구점 개점</li>
                        <li>보험클리닉 과천점 개점</li>
                        <li>보험클리닉 덕소점 개점</li>
                        <li>보험클리닉 송탄점 개점</li>
                        <li>보험클리닉 마산점 개점</li>
                        <li>보험클리닉 평택안중점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2020.01</strong>
                    <ul>
                        <li>한국소비자지수 1위 수상 <br>(주최 : 한경비즈니스)</li>
                        <li>KBS 불우이웃돕기 성금 방송보도</li>
                        <li>보험클리닉 광주계림점 개점</li>
                        <li>보험클리닉 오점점 개점</li>
                        <li>보험클리닉 대치역점 개점</li>
                        <li>보험클리닉 화곡역점 개점</li>
                        <li>보험클리닉 영종도점 개점</li>
                        <li>보험클리닉 양덕점 개점</li>
                        <li>보험클리닉 안양역점 개점</li>
                        <li>보험클리닉 시흥점 개점</li>
                        <li>보험클리닉 신갈점 개점</li>
                        <li>보험클리닉 까치산역점 개점</li>
                        <li>보험클리닉 범계역점 개점</li>
                        <li>보험클리닉 신도림점 개점</li>
                        <li>보험클리닉 남대구점 개점</li>
                        <li>보험클리닉 노원점 개점</li>
                        <li>보험클리닉 구월점 개점</li>
                        <li>보험클리닉 흑석점 개점</li>
                        <li>보험클리닉 평촌점 개점</li>
                        <li>보험클리닉 창동점 개점</li>
                        <li>보험클리닉 일산점 개점</li>
                        <li>보험클리닉 노원점 개점</li>
                        <li>업계 최초 정규직 보험설계사 조직 구축(EFA)</li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="id2019" class="history_contents">
            <div class="year_visual">
                <span class="year"><img src="/img/intro/history/txt_num2019-18.png" alt="2019-2018"></span>
                <span class="text">
                    대규모 자금 유치에 성공, 내방형 점포를<br>
                    오픈하는 등 다채널 전략을 펼치며 경쟁력을 확보하고 있다.
                </span>
                <em class="img"><img src="/img/intro/history/img_id2019.jpg" alt=""></em>
            </div>
            <div class="step_contens">
                <div class="step">
                    <strong><i></i>2019.12</strong>
                    <ul>
                        <li>우수GA선정 피플라이프<br>(주최 : 한국보험대리점협회)</li>
                        <li>보험클리닉 광장점 개점</li>
                        <li>보험클리닉 금천점 개점</li>
                        <li>보험클리닉 의정부점 개점</li>
                        <li>보험클리닉 서귀포점 개점</li>
                        <li>보험클리닉 창원중앙점 개점</li>
                        <li>보험클리닉 오산점 개점</li>
                        <li>보험클리닉 신림점 개점</li>
                        <li>보험클리닉 수유점 개점</li>
                        <li>보험클리닉 광명점 개점</li>
                        <li>보험클리닉 장안점 개점</li>
                        <li>보험클리닉 청계천점 개점</li>
                        <li>보험클리닉 부평역점 개점</li>
                        <li>롯데홈쇼핑 방송 LIVE</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2019.11</strong>
                    <ul>
                        <li>보험클리닉 산본점 개점</li>
                        <li>보험클리닉 반월당점 개점</li>
                        <li>보험클리닉 구로점 개점</li>
                        <li>보험클리닉 권선점 개점</li>
                        <li>보험클리닉 서울대입구점 개점</li>
                        <li>보험클리닉 사가정점 개점</li>
                        <li>보험클리닉 동묘점 개점</li>
                        <li>보험클리닉 전주효자점 개점</li>
                        <li>보험클리닉 진장점 개점</li>
                        <li>보험클리닉 가야점 개점</li>
                        <li>보험클리닉 김해점 개점</li>
                        <li>보험클리닉 서현점 개점</li>
                        <li>보험클리닉 청량리점 개점</li>
                        <li>롯데홈쇼핑 방송 LIVE</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2019.10</strong>
                    <ul>
                        <li>보험클리닉 미아점 개점</li>
                        <li>보험클리닉 부천점 개점</li>
                        <li>보험클리닉 응암점 개점</li>
                        <li>보험클리닉 압구정로데오점 개점</li>
                        <li>보험클리닉 천안성정점 개점</li>
                        <li>보험클리닉 춘천점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2019.09</strong>
                    <ul>
                        <li>보험클리닉 대구율하점 개점</li>
                        <li>보험클리닉 판교점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2019.08</strong>
                    <ul>
                        <li>보험클리닉 성안점 개점</li>
                        <li>보험클리닉 고잔점 개점</li>
                        <li>보험클리닉 한티점 개점</li>
                        <li>롯데홈쇼핑 방송 LIVE</li>
                        <li>하이라이트TV 행복플랜 하이라이트 방영</li>
                        <li>리빙TV 넘버원 행복플랜 방영</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2019.07</strong>
                    <ul>
                        <li>보험클리닉 장발산점 개점</li>
                        <li>보험클리닉 삼산점 개점</li>
                        <li>보험클리닉 상무점 개점</li>
                        <li>보험클리닉 서초점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2019.06</strong>
                    <ul>
                        <li>보험클리닉 간석점 개점</li>
                        <li>보험클리닉 수원점 개점</li>
                        <li>보험클리닉 목동점 개점</li>
                        <li>보험클리닉 사상점 개점</li>
                        <li>보험대리점 브랜드평판 1위 선정(19년 6월) <br>(주최 : 한국기업평판연구소)</li>
                        <li>대한민국고객만족브랜드대상 수상 <br>(주최 : 주간동아)</li>
                        <li>보험클리닉 월평점 개점</li>
                        <li>보험클리닉 가양점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2019.05</strong>
                    <ul>
                        <li>보험클리닉 월드타워점 개점</li>
                        <li>보험클리닉 탄방점 개점</li>
                        <li>보험대리점 브랜드평판 1위 선정(19년 5월) <br>(주최 : 한국기업평판연구소)</li>
                        <li>한국고객만족도 1위 수상 <br>(주최 : 한경비즈니스)</li>
                        <li>TM조직 론칭</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2019.04</strong>
                    <ul>
                        <li>‘더DREAM’쇼 진행</li>
                        <li>보험대리점 브랜드평판 1위 선정(19년 4월) <br>(주최 : 한국기업평판연구소)</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2019.03</strong>
                    <ul>
                        <li>보험클리닉 해운대점 개점</li>
                        <li>보험클리닉 작전점 개점</li>
                        <li>보험클리닉 영등포점 개점</li>
                        <li>보험클리닉 잠실점 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2019.01</strong>
                    <ul>
                        <li>보험대리점 브랜드평판 1위 선정(19년 1월) <br>(주최 : 한국기업평판연구소)</li>
                        <li>한국소비자만족지수 1위 수상 <br>(주최 : 한경비즈니스)</li>
                        <li>사내 이웃돕기 모금운동(KBS 보도)</li>
                        <li>610억 투자유치 성공</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2018.12</strong>
                    <ul>
                        <li>랭키닷컴 재무설계/컨설팅 분야 1위 선정 <br>(주최 : 랭키닷컴)</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2018.11</strong>
                    <ul>
                        <li>매경 광고대상 신문부문 캠페인 대상 수상 <br>(주최 : 매경미디어센터)</li>
                        <li>보험대리점 브랜드평판 1위 선정(18년 11월) <br>(주최 : 한국기업평판연구소)</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2018.10</strong>
                    <ul>
                        <li>‘당신의 꿈을 위한 多 Dream Show’ 진행</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2018.09</strong>
                    <ul>
                        <li>고객내방형 보험샵 ‘보험클리닉’ 개점</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2018.06</strong>
                    <ul>
                        <li>차세대 영업지원시스템 ‘어시스트‘ 론칭</li>
                        <li>고객용 보험증권관리 앱＇보험클리닉‘ Grand Open</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2018.05</strong>
                    <ul>
                        <li>‘2018 피플라이프 이노베이션 쇼‘ 진행</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2018.01</strong>
                    <ul>
                        <li>홈쇼핑채널 진출</li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="id2017" class="history_contents">
            <div class="year_visual">
                <span class="year"><img src="/img/intro/history/txt_num2017.png?v=23011812" alt="2017-2013"></span>
                <span class="text">
                    공중파 TV 광고 실시, <br>
                    중소기업 골프 대회 개최, 언론사 브랜드 제휴 개인고객<br>
                    전문 브랜드 론칭 등 전방위적인 마케팅 활동
                </span>
                <em class="img"><img src="/img/intro/history/img_id2017.jpg" alt=""></em>
            </div>
            <div class="step_contens">
                <div class="step">
                    <strong><i></i>2017.12</strong>
                    <ul>
                        <li>매출 1,400억원, 법인고객 수 40,000사 돌파</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2017.06</strong>
                    <ul>
                        <li>자회사 ‘피플라이프재무설계‘ 흡수합병</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2016.12</strong>
                    <ul>
                        <li>법인고객 수 35,000사 돌파</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2016.08</strong>
                    <ul>
                        <li>이노비즈협회 전략적 업무제휴</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2015.08</strong>
                    <ul>
                        <li>‘피플라이프 중소기업CEO클리닉‘ 개최</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2014.04</strong>
                    <ul>
                        <li>매경닷컴(현 매경비즈) 업무제휴 및 ‘매경경영지원본부＇브랜드 출범</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2013.06</strong>
                    <ul>
                        <li>공중파 TV 광고 실시</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2013.04</strong>
                    <ul>
                        <li>자회사 ‘피플라이프재무설계’ 출범</li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="id2012" class="history_contents">
            <div class="year_visual">
                <span class="year"><img src="/img/intro/history/txt_num2012.png" alt="2012-2003"></span>
                <span class="text">
                    체계적인 지원 시스템과 세무·법무·노무·부동산 등 <br>
                    전문가 네트워크로 법인 컨설팅 분야에서<br>
                    독보적인 영역을 구축
                </span>
                <em class="img"><img src="/img/intro/history/img_id2012.jpg" alt=""></em>
            </div>
            <div class="step_contens">
                <div class="step">
                    <strong><i></i>2012.12</strong>
                    <ul>
                        <li>매출 660억, 법인고객수 9,000사 돌파</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2011.03</strong>
                    <ul>
                        <li>가업승계 특화</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2010.12</strong>
                    <ul>
                        <li>전국 네트워크 영업망 구축</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2009.02</strong>
                    <ul>
                        <li>수도권 중심의 네트워크 구축</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2006.02</strong>
                    <ul>
                        <li>경영효율화컨설팅 상품화</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2005.07</strong>
                    <ul>
                        <li>분업화를 통한 영업지원시스템 개발</li>
                    </ul>
                </div>
                <div class="step">
                    <strong><i></i>2003.12</strong>
                    <ul>
                        <li>피플라이프 설립</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="year_list bottom">
            <ul>
                <li data-id="id2023" class="on"><button type="button">현재-2023</button></li>
                <li data-id="id2022"><button type="button">2022~2020</button></li>
                <li data-id="id2019"><button type="button">2019~2018</button></li>
                <li data-id="id2017"><button type="button">2017~2013</button></li>
                <li data-id="id2012"><button type="button">2012~2003</button></li>
            </ul>
        </div>
    </section>
</main>
<!-- /main -->
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/intro/history.scss';
</style>
