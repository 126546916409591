<template>
    <section id="bohumConsulting" class="section">
        <h3 class="title">믿을 수 있는 피플라이프 보험컨설팅</h3>
        <ul>
            <li>
                <img :src="imgPath+'pic_efa_consulting01.png'" alt="">
                <em>회사설립</em>
                <strong><i>2003</i><span>년</span></strong>
            </li>
            <li>
                <img :src="imgPath+'pic_efa_consulting02.png'" alt="">
                <em>보험 전문가 <span>(2023년 6월 설계사 수 기준)</span></em>
                <strong><i>4,050</i><span>명</span></strong>
            </li>
            <li>
                <img :src="imgPath+'pic_efa_consulting03.png'" alt="">
                <em>사업단 수 <span>(2023년 6월 지점현황 기준)</span></em>
                <strong><i>135</i><span>개</span></strong>
            </li>
        </ul>
        <p class="source">(출처: 2023 상반기 생명보험협회 통합공시자료)</p>
    </section>
</template>



<script>
export default {
    name: 'bohumConsulting',
    data() {
        return {
            imgPath: '/img/common/contents/bohumConsulting/'
        }
    }
}
</script>



<style lang="scss" scoped>
    $imgPath : "/img/common/contents/bohumConsulting/";

    #bohumConsulting {
        overflow: hidden; width:100%; height:161.3333vw; padding:13.6666vw 5.3333vw; background:url(#{$imgPath}bg_efa_consulting.jpg) no-repeat left top; background-size: 100%;
        h3.title{
            letter-spacing: -1.6px; color:#fff;
            span{
                display: block; margin-bottom: 2.0666vw; letter-spacing: 0em; font-weight: 100; font-size: 4vw;
            }
        }
        ul{
            margin-top:12.8vw;
            li{
                position:relative; height: 32vw; margin-top:8vw; padding:4.8vw 0 0 44.6666vw; color:#fff;
                &:first-child{
                    margin-top:0;
                }
                img{
                    position:absolute; top:0; left:0; width:32vw; height:32vw;
                }
                em{
                    display:block; font-size:4vw;
                    span{
                        font-size:2.4vw;
                    }
                }
                strong{
                    display: block; margin-top: 4.2666vw; font-weight: 400; font-size: 14.6666vw; line-height: .8em;
                    i{font-family: "MongolianBait";}
                    span{
                        font-size:4vw;
                    }
                }
            }
        }
        .source{ color:#fff; font-size: 3vw; margin-top: 3.2vw; text-align: center;}
    }
</style>