<template>
<!-- main -->
<main id="container" class="newScrollAction" data-name="recruitEfa" >
    <h1 class="screen_out">정규직 개인영업(EFA) 채용</h1>

    <!-- 메인비주얼 -->
    <section class="visual">
        <span class="txt"><img :src="imgPath+'txt_visual.png'" alt="#피플라이프 정규직 EFA로 커리어 업그레이드!"></span>
        <span class="img"><img :src="imgPath+'img_visual.png'" alt=""></span>
    </section>
    <!-- //메인비주얼 -->

    <!-- EFA소개 -->
    <section class="explanation">
        <strong class="tit">
            “ 보험설계사 경력을 살려서<br>
            &nbsp;&nbsp;오늘부터 <b>정규직</b> 되세요 ”
        </strong>
        <p class="img"><img :src="imgPath+'img_explanation.png'" alt="보험설계사 설명"></p>
    </section>
    <!-- //EFA소개 -->

    <!-- 차별화 -->
    <!-- <section class="discrimination">
        <h3 class="title">EFA 상담매니저만의 차별화 3가지</h3>
        <ol class="list">
            <li>
                <strong>직업의 안정</strong>
                <span>
                    정규직으로 4대보험 및 <br>
                    복리후생 제공
                </span>
                <img :src="imgPath+'ico_discrimination01.png'" alt="">
            </li>
            <li>
                <strong>소득의 안정</strong>
                <span>
                    월 250만원 기본급으로 <br>
                    안정적인 소득보장
                </span>
                <img :src="imgPath+'ico_discrimination02.png'" alt="">
            </li>
            <li>
                <strong>고소득 실현</strong>
                <span>
                    성과에 따른 인센티브로 <br>
                    고소득 가능
                </span>
                <img :src="imgPath+'ico_discrimination03.png'" alt="">
            </li>
        </ol>
    </section> -->
    <!-- //차별화 -->

    <!-- 토크쇼 -->
    <section id="talkShow">
        <div class="inner">
            <h3 class="tit_section">보험설계사를 정규직으로?<br>이번에는 EFA다!</h3>
            <p class="desc_section">피플라이프 EFA는 <strong>회사에 소속된 근로자 신분으로</strong><br> <strong>4대보험이 적용되고, 퇴직금을 별도로 받아</strong> 위촉직과는<br> 차별화를 두고 있습니다.</p>
            <button class="videoLayer" data-src="https://www.youtube.com/embed/Z9QQbv17mBc" data-title="보험설계사를 정규직으로? ㅣ 이번에는 EFA다" data-type="youtube" type="button" title="영상재생">
                <img class="thumb" src="https://img.youtube.com/vi/Z9QQbv17mBc/maxresdefault.jpg" alt="스크린샷">
            </button>
            <button class="btn_videoLayer" type="button"><img class="bi" :src="imgPath+'talkShow_logo.png'" alt="강과장의 보험토크쇼">영상보기<i class="ico"><img src="" data-images-path="/image/svg/play01.svg" alt=""></i></button>
        </div>
    </section>
    <!-- //토크쇼 -->

    <!-- 채용절차 -->
    <section class="hire_step">
        <h3 class="title">정규직 보험클리닉(EFA)<br>채용절차</h3>
        <p class="description">서류 전형부터 면접까지! 보험클리닉 입사에 대한 모든 것</p>
        <div class="step_cont">
            <ol class="step_list">
                <li class="step1">
                    <img :src="imgPath+'img_hire01.png'" alt="">
                    <em>서류전형</em>
                </li>
                <li class="step2">
                    <img :src="imgPath+'img_hire02.png'" alt="">
                    <em>실무진 면접</em>
                </li>
                <li class="step3">
                    <img :src="imgPath+'img_hire03.png'" alt="">
                    <em>임원면접</em>
                </li>
                <li class="step4">
                    <img :src="imgPath+'img_hire04.png'" alt="">
                    <em>최종합격</em>
                </li>
            </ol>
        </div>
        <div class="info_table end">
            <div class="table_css">
                <table summary="직군별 채용안내에 대한 내용을 확인 할 수 있습니다.">
                    <caption>모집부분 및 채용규모</caption>
                    <colgroup>
                        <col style="width:24%;">
                        <col style="width:*;">
                    </colgroup>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <th>모집분야</th>
                            <td>정규직 보험클리닉(EFA) 상담매니저</td>
                        </tr>
                        <tr>
                            <th>주요업무</th>
                            <td>
                                <ul>
                                    <li>보험비교분석 시스템을 활용한 보험 분석 및 대안 제시</li>
                                    <li>고객응대 및 고객상담을 통한 영업</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>채용규모</th>
                            <td>00명</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table_css">
                <table>
                    <caption>전형별 일정</caption>
                    <colgroup>
                        <col style="width:24%;">
                        <col style="width:*;">
                    </colgroup>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <th>서류전형</th>
                            <td>상시변경</td>
                        </tr>
                        <tr>
                            <th>면접일정</th>
                            <td>상시변경</td>
                        </tr>
                        <tr>
                            <th>인턴과정</th>
                            <td>상시변경</td>
                        </tr>
                    </tbody>
                </table>
                <p class="cmt_table">※ 문의처 : 카카오톡 오픈채팅 - 1:1 채팅에서 ‘보험클리닉’을 검색하세요.</p>
                <div class="recruit_close" style="top:57%;" ><p>해당 공고는 아쉽게도 마감되었습니다</p></div>
            </div>
            <div class="table_css">
                <table>
                    <caption>지원방법</caption>
                    <tbody>
                        <tr>
                            <td class="align-center">
                                <p class="mb-10"><strong>사람인 즉시지원 또는 자사이력서 작성 후 이메일 제출 → <a href="mailto:bohumclinic@peoplelife.co.kr">bohumclinic@peoplelife.co.kr</a></strong></p>
                                <p>※ 문의처 : 카카오톡 오픈채팅 - 1:1 채팅에서 <br> ‘보험클리닉’을 검색하세요.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table_css">
                <table>
                    <caption>고용형태 및 근무조건</caption>
                    <colgroup>
                        <col style="width:24%;">
                        <col style="width:*;">
                    </colgroup>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <th>고용형태</th>
                            <td>
                                <ul>
                                    <li>학습형 인턴과정 1개월</li>
                                    <li>인턴과정 수료 후 정규직 전환(수습평가 기간 6개월) </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>근무시간</th>
                            <td>
                                <ul>
                                    <li>주 5일, 일 8시간, 로테이션 근무<br> (주말근무 포함)</li>
                                    <li>일요일 휴무</li>
                                    <li>주말근무 시 평일 대체휴무 부여</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>연봉</th>
                            <td>
                                연 3,000만원 기본급 (4대보험) + 인센티브
                                <ol>
                                    <li> 인턴기간 <span class="font">中</span> 100만(세전) 지급</li>
                                </ol>
                            </td>
                        </tr>
                        <tr>
                            <th>채용지역</th>
                            <td> 전국 </td>
                        </tr>
                        <tr>
                            <th>활동지원</th>
                            <td>
                                <ul>
                                   <li>매월 고객 상담 DB제공</li>
                                   <li>활동비 제공</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table_css">
                <table>
                    <caption>지원자격 및 우대사항</caption>
                    <colgroup>
                        <col style="width:20%;">
                        <col style="width:*;">
                    </colgroup>
                    <thead></thead>
                    <tbody>
                        <tr>
                            <th>지원자격</th>
                            <td>
                                <ul>
                                    <li>초대졸 이상 또는 졸업예정자</li>
                                    <li>학습형 인턴과정 참여 가능자</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>우대사항</th>
                            <td>
                                <ul>
                                    <li>DB영업 有경험자</li>
                                    <li>생명보험, 손해보험, 변액보험판매자격 보유자 (인턴기간 중 취득 가능)</li>
                                    <li>금융업계 1년 이상 종사자 <span class="color-ff0000">(보험설계사 우대)</span></li>
                                    <li>AFPK, CFP등 금융자격증 보유자</li>
                                    <li>여성, 장교 / 부사관 출신</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>유의사항</th>
                            <td>
                                <ul>
                                    <li class="bullet_ex">[ 채용제한 ]</li>
                                    <li>신용등급 8등급 이하(점수 534점)</li>
                                    <li>보험회사 3년내 이직횟수 3회 이상자</li>
                                    <li>보증보험 가입 불가자</li>
                                    <li>지원서 내용이 사실과 다르거나 문서로 증빙이 불가능할 경우</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table_css">
                <table>
                    <caption>접수기간</caption>
                    <tbody>
                        <tr>
                            <td class="align-center">
                                <p class="txt_dday">상시채용</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="recruit_close"><p>해당 공고는 아쉽게도 마감되었습니다</p></div>
            </div>
        </div>
    </section>
    <!-- //채용절차 -->

    <!-- 급여체계 -->
    <section class="system">
        <h3 class="title">정규직 보험클리닉(EFA)<br>급여체계</h3>
        <p class="img"><img :src="imgPath+'img_system.png'" alt="기본급 상담매니저 : 기본급 월 250만원 + 성과인센티브 생·손보 全 상품 성과인센티브 * 자세한 내용 별도문의 + 활동관련 Promotion 환산P’ 달성 규모에 따라 차등지급 * 자세한 내용 별도문의"></p>
    </section>
    <!-- //급여체계 -->

    <!-- 입사지원신청 -->
    <section id="applyForm"  class="section" data-recruit-state="deadline">  <!-- 종료시 data-recruit-state="deadline" -->
        <div class="inner">
            <h3 class="tit_section"><mark>정규직 보험클리닉(EFA)<br>지원하기</mark></h3>
            <li class="row row_carrer_life row_css" data-check-pass="true" data-inbound-prop="lifeCareerYn" data-inbound-value="Y" data-check-comment="생보경력유무를 선택하세요">
                <label class="btn_carrer_life btnCarrerLife">
                    <input name="carrer_life" value="Y" type="radio" data-auto-focus="false" tabindex="-1" checked>
                    <em class="theme_border_color"><!--있음--></em>
                </label>
                <label class="btn_carrer_life btnCarrerLife">
                    <input name="carrer_life" value="N" type="radio" data-auto-focus="false" tabindex="-1">
                    <em class="theme_border_color"><!--없음--></em>
                </label>
            </li>
            <li class="row row_carrer_damage row_css" data-check-pass="true" data-inbound-prop="damageCareerYn" data-inbound-value="Y" data-check-comment="손보경력유무를 선택하세요">
                <label class="btn_carrer_damage btnCarrerDamage">
                    <input name="carrer_damage" value="Y" type="radio" data-auto-focus="false" tabindex="-1" checked>
                    <em class="theme_border_color"><!--있음--></em>
                </label>
                <label class="btn_carrer_damage btnCarrerDamage">
                    <input name="carrer_damage" value="N" type="radio" data-auto-focus="false" tabindex="-1">
                    <em class="theme_border_color"><!--없음--></em>
                </label>
            </li>
            <li class="row row_file row_css" data-check-pass="false" data-inbound-prop="fileAttachId" data-inbound-value="" data-check-comment="입사지원서를 업로드하세요">
                <button type="button"><img class="ico" src="" data-images-path="/image/svg/clip01.svg"></button>
                <span class="file_name"></span>
                <input type="file" class="hidden">
            </li>
            <div class="loadForm"></div>
            <div class="box_info">
                <p class="tit">정규직 보험클리닉(EFA) <strong>입사지원서 다운받기</strong></p>
                <p class="desc">
                    입사지원서를 작성하신 후 제출해주시기 바랍니다.<br>
                    <span>※ 다른 양식의 입사지원서로도 지원 가능합니다.<br>(잡코리아, 사람인 이력서도 제출 가능)</span>
                </p>
                <a class="link_download" href="" data-images-path="/data/doc/resume.docx" download><span>입사지원서 다운로드</span><img class="ico" src="" data-images-path="/image/svg/download02.svg"></a>
            </div>
        </div>
    </section>
    <!-- //입사지원신청 -->

    <!-- 채용문의 -->
    <section class="question">
        <div class="box">
            <h3>채용문의</h3>
            <a href="tel:02-2085-6737">02.2085.6737</a>
            <em>bohumclinic@peoplelife.co.kr</em>
            <p>
                <span>평일 09:00~18:30</span>
                <span>근무시간 외 E-mail 문의</span>
            </p>
        </div>
        <span class="txt1">고용을 넘어 안정적인 소득을 원하신다면?</span>
        <em class="txt2">
            정규직 보험상담매니저에 도전하세요!<br>
            <strong>당신이 그 기회의 주인공 입니다.</strong>
        </em>
    </section>
    <!-- //채용문의 -->

    <button class="floting anchor" data-target="#applyForm" type="button"><img :src="imgPath+'img_floting.png'" alt="입사지원"></button>
</main>
<!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/recruit/recruitEfa/'
        }
    }
}
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/recruit/recruitEfa.scss';
</style>